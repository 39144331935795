import { useFormikContext } from 'formik';
import { useState } from 'react';
import { List, Popup, Radio, Table } from 'semantic-ui-react';

import { TaskGroupHierarchy } from 'daos/external_integration_enums';
import ClickableText from 'features/common/clickable_text';
import { LpIcon, questionCircleSolid } from 'features/common/lp_icon';
import LpPopUp from 'features/common/lp_popup';
import { JiraProjectModalTableDeleteCell } from 'features/jira_project/modal/common/jira_project_modal_table_delete_cell';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { SubfolderExampleModal } from 'features/jira_project/modal/sections/sync_settings_section/__tests__/subfolder_example_modal';
import {
  getSubFolderOrganizationOptions,
  isFlatListDisabled,
} from 'features/jira_project/modal/sections/sync_settings_section/helpers';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  SubFolderOrganizationValues,
} from 'features/jira_project/modal/types';
import { gray600 } from 'style/variables';

import 'features/jira_project/modal/jira_project_modal.scss';

export const SubFolderOrganisationRow = () => {
  const [exampleSelected, setExampleSelected] = useState<SubFolderOrganizationValues | undefined>(undefined);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const { isPremiumJiraUser, canSelectFlatList } = useJiraProjectModalContext();
  const { values, setFieldValue } = useFormikContext<JiraProjectModalFormValues>();
  const { taskGroupHierarchy } = values;
  const subFolderOrganizationOptions = getSubFolderOrganizationOptions(isPremiumJiraUser);
  const openPopup = () => setIsPopUpOpen(true);
  const closePopup = () => setIsPopUpOpen(false);

  const helpText = (
    <div className="jira-project-modal__content-sync-settings-subfolder-organization-help-text">
      <div>Tasks are displayed as a flat list if no Epics or custom hierarchy levels exist.</div>{' '}
      <div>
        <b>Please note:</b> Once a Sub-Folder structure is set up, it cannot be reverted to a flat list. However, you
        can always change a flat list to a Sub-Folder setup at any time.
      </div>
    </div>
  );

  const disabledText = (
    <div>
      <b>Please note:</b> This option is disabled because once a Sub-Folder structure is set up, it cannot be reverted
      to a flat list.
    </div>
  );

  const subFolderOrganizationTitle = (
    <>
      Sub-folder Organization
      <LpPopUp placement="top" content={helpText} trigger={<LpIcon color={gray600} icon={questionCircleSolid} />} />
    </>
  );

  const handleViewExample = (option: SubFolderOrganizationValues) => {
    return () => setExampleSelected(option);
  };

  const handleResetSubFolderOrganization = () => {
    setFieldValue(JiraProjectModalFormFields.TaskGroupHierarchy, TaskGroupHierarchy.GroupFlatLevel);
  };

  const disabledRadioButton = (option: SubFolderOrganizationValues) => {
    return (
      <span onMouseEnter={openPopup} onMouseLeave={closePopup}>
        <Popup
          transition="false"
          trigger={
            <HierarchyLevelRadioButton
              option={option}
              setFieldValue={setFieldValue}
              taskGroupHierarchy={taskGroupHierarchy}
              canSelectFlatList={canSelectFlatList}
            />
          }
          content={disabledText}
          open={isPopUpOpen}
          position={'top center'}
          size="small"
        />
      </span>
    );
  };

  return (
    <JiraProjectModalTableRow>
      <JiraProjectModalTableNameCell content={subFolderOrganizationTitle} />
      <Table.Cell>
        <List>
          <List.Item>
            <List.List className="jira-project-modal__content-sync-settings-subfolder-organization">
              {subFolderOrganizationOptions.map((option, index) => {
                return (
                  <List.Item
                    key={index}
                    className="jira-project-modal__content-sync-settings-subfolder-organization-option"
                  >
                    {isFlatListDisabled(option.id, canSelectFlatList) ? (
                      disabledRadioButton(option)
                    ) : (
                      <HierarchyLevelRadioButton
                        taskGroupHierarchy={taskGroupHierarchy}
                        option={option}
                        setFieldValue={setFieldValue}
                        canSelectFlatList={canSelectFlatList}
                      />
                    )}
                    <div>
                      <b>{option.name}</b>:
                      <span className="jira-project-modal__content-sync-settings-subfolder-organization-description-text">
                        {option.description}
                      </span>
                      <ClickableText text={'See Example'} onClick={handleViewExample(option)} />
                    </div>
                    {exampleSelected && (
                      <SubfolderExampleModal
                        setExampleSelected={setExampleSelected}
                        exampleSelected={exampleSelected}
                      />
                    )}
                  </List.Item>
                );
              })}
            </List.List>
          </List.Item>
        </List>
      </Table.Cell>
      <JiraProjectModalTableDeleteCell
        onClick={handleResetSubFolderOrganization}
        enabled={taskGroupHierarchy !== TaskGroupHierarchy.GroupFlatLevel}
      />
    </JiraProjectModalTableRow>
  );
};

const HierarchyLevelRadioButton = ({
  option,
  taskGroupHierarchy,
  setFieldValue,
  canSelectFlatList,
}: {
  option: SubFolderOrganizationValues;
  taskGroupHierarchy: TaskGroupHierarchy;
  setFieldValue: (field: string, value: any) => void;
  canSelectFlatList: boolean;
}) => {
  const handleChange = () => setFieldValue(JiraProjectModalFormFields.TaskGroupHierarchy, option.id);
  const isDisabled = !canSelectFlatList && option.id === TaskGroupHierarchy.GroupFlatLevel;

  return (
    <Radio
      onChange={handleChange}
      value={option.name}
      checked={taskGroupHierarchy === option.id}
      disabled={isDisabled}
    />
  );
};
