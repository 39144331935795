import { groupBy, sortBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { Assignment } from 'daos/assignment';
import { ItemType } from 'daos/enums';
import { Item, OrganizationUser, User } from 'daos/model_types';
import { mapAssigneesByOrgUserIdOrderedByDoneBoolean } from 'lib/display_helpers/assignee/assignee';
import { Assignee } from 'lib/display_helpers/assignee/types';
import { isAssignment, getItemsById, getItemForId } from 'redux/entities/selectors/item';
import { RootState } from 'redux/root_reducer';

import { createCacheByIdConfig, getNumberArgument } from './shared';
import { getIsWorkspaceUserDisconnectedForOrgUserId, getOrganizationUserForId, getUsersById } from './user';

export const getAssignmentById = ({ entities }: RootState, id: number) => {
  const item = entities.items[id];
  return item && isAssignment(item) ? (item as Assignment) : undefined;
};

const getAssignments = createSelector(
  getItemsById,
  (items) => Object.values(items).filter(isAssignment) as Array<Assignment>
);

const getAssignmentsIndexedByParentId = createSelector(getAssignments, (items) => groupBy(items, (e) => e.parent?.id));

export const getAssignmentsForItemId = createCachedSelector(
  getAssignmentsIndexedByParentId,
  getNumberArgument,
  (assignments, id) => assignments[id] || ([] as ReadonlyArray<Assignment>)
)(createCacheByIdConfig());

const getAssignmentsOrderedByPriorityForItemId = createCachedSelector(getAssignmentsForItemId, (assignments) => {
  return sortBy(assignments, 'priority');
})(createCacheByIdConfig());

export const getOrganizationUsersOfAssignmentsForTaskId = createCachedSelector(
  getAssignmentsForItemId,
  (state: RootState) => state,
  (assignments, state) =>
    assignments.reduce((acc: Array<OrganizationUser>, assignment) => {
      if (assignment.organizationUser) {
        const orgUser = getOrganizationUserForId(state, assignment.organizationUser.id);
        if (orgUser && !acc.includes(orgUser)) {
          acc.push(orgUser);
        }
      }
      return acc;
    }, [])
)(createCacheByIdConfig());

export const getUsersOfAssignmentsForTaskId = createCachedSelector(
  getOrganizationUsersOfAssignmentsForTaskId,
  getUsersById,
  (organizationUsers, users) =>
    organizationUsers.reduce((acc: Array<User>, orgUser) => {
      const assignmentUser = users[orgUser.user.id];
      if (assignmentUser && !acc.includes(assignmentUser)) {
        acc.push(assignmentUser);
      }
      return acc;
    }, [])
)(createCacheByIdConfig());

const getAssigneeForAssignment = (state: RootState, assignment: Item): Assignee => {
  const done = !!assignment.doneDate;
  const orgUserId = assignment.organizationUser?.id ?? 0;
  const isDisconnectedFromWorkspace = getIsWorkspaceUserDisconnectedForOrgUserId(state, orgUserId);
  const username = getOrganizationUserForId(state, orgUserId)?.username ?? 'unassigned';

  return { done, disconnected: isDisconnectedFromWorkspace, orgUserId, username };
};

const getAssigneeMapForTaskId = createCachedSelector(
  getAssignmentsOrderedByPriorityForItemId,
  (state: RootState) => state,
  (assignments, state) => {
    const assignees = assignments.map((assignment) => getAssigneeForAssignment(state, assignment));

    return mapAssigneesByOrgUserIdOrderedByDoneBoolean(assignees);
  }
)(createCacheByIdConfig());

export const getAssigneeMapForAssignmentOrTaskId = createCachedSelector(
  getItemForId,
  (state: RootState) => state,
  (item, state) => {
    if (item?.itemType === ItemType.ASSIGNMENTS) {
      const assignee = getAssigneeForAssignment(state, item);
      return new Map([[assignee.orgUserId, assignee]]);
    }

    if (item?.itemType === ItemType.TASKS) {
      return getAssigneeMapForTaskId(state, item.id);
    }

    return new Map<number, Assignee>();
  }
)(createCacheByIdConfig());
