import { useSelector } from 'react-redux';

import {
  getFormattedDateOrEmptyString,
  getHoursOrEmptyString,
  getHoursOrEmptyStringWithNoRounding,
  getValueOrEmptyString,
} from 'containers/shared/custom_column/display_helpers';
import { Columns, FrontendColumns, StandardColumns } from 'containers/shared/custom_column/enum';
import { ItemMetricValueProps } from 'containers/shared/custom_column/types';
import { ItemMetrics } from 'daos/model_types';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { formatAsHoursWithNoRounding } from 'lib/display_helpers/format_as_hours';
import { convertSecondsToHours } from 'lib/helpers';
import { getItemMetricValueProps } from 'redux/entities/selectors/item_metric';
import { getWorkLimitValuesForItemId } from 'redux/entities/selectors/work_limits_values';

export const getItemMetricValueForColumn = ({
  column,
  displayEmptyStringInsteadOfCount,
  formatLocalDate,
  itemMetricProps,
  workLimitHours,
}: {
  column: Columns;
  displayEmptyStringInsteadOfCount: boolean;
  formatLocalDate: (date: string) => string;
  itemMetricProps: ItemMetricValueProps;
  workLimitHours?: number | null;
}) => {
  const getDateOrEmptyString = (date: string | undefined) => getFormattedDateOrEmptyString(date, formatLocalDate);

  const getCountOrEmptyString = (count: number | undefined) =>
    displayEmptyStringInsteadOfCount ? '' : getValueOrEmptyString(count);

  switch (column) {
    case FrontendColumns.EarliestDoneDate:
      return getDateOrEmptyString(itemMetricProps.earliestDoneDate);
    case FrontendColumns.EffectiveTargetFinish:
      return getDateOrEmptyString(itemMetricProps.effectiveTargetFinish);
    case FrontendColumns.EffectiveTargetStart:
      return getDateOrEmptyString(itemMetricProps.effectiveTargetStart);
    case FrontendColumns.LatestDoneDate:
      return getDateOrEmptyString(itemMetricProps.latestDoneDate);
    case FrontendColumns.RollupEarliestActiveTargetFinish:
      return getDateOrEmptyString(itemMetricProps.rollupEarliestActiveTargetFinish);
    case FrontendColumns.RollupEarliestTargetStart:
      return getDateOrEmptyString(itemMetricProps.rollupEarliestTargetStart);
    case FrontendColumns.RollupLatestTargetFinish:
      return getDateOrEmptyString(itemMetricProps.rollupLatestTargetFinish);

    case StandardColumns.Logged:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.loggedWorkHours);

    case StandardColumns.LoggedBillable:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.loggedBillable);

    case StandardColumns.LoggedNonBillable:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.loggedNonBillable);

    case StandardColumns.PercentComplete:
      return itemMetricProps.percentComplete !== undefined ? `${itemMetricProps.percentComplete}%` : '';

    case StandardColumns.PercentTasksComplete:
      return itemMetricProps.percentTasksComplete !== undefined ? `${itemMetricProps.percentTasksComplete}%` : '';

    case FrontendColumns.PercentCompleteNumericValueAsString:
      return String(itemMetricProps.percentComplete);

    case StandardColumns.OnHoldHours:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.onHoldHours);

    case FrontendColumns.OnHoldHoursRange:
      return `${getHoursOrEmptyString(itemMetricProps.onHoldHoursRange?.lowOnHoldHours)} - ${getHoursOrEmptyString(
        itemMetricProps.onHoldHoursRange?.highOnHoldHours
      )}`;

    case StandardColumns.RemainingWorkExpected:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.remainingWorkExpectedHours);

    case StandardColumns.RemainingWorkRange:
      return `${getHoursOrEmptyString(
        itemMetricProps.remainingWorkRange?.lowRemainingWorkHours
      )} - ${getHoursOrEmptyString(itemMetricProps.remainingWorkRange?.highRemainingWorkHours)}`;

    case StandardColumns.RemainingWorkHigh:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.highRemainingWorkHours);

    case StandardColumns.RemainingWorkLow:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.lowRemainingWorkHours);

    case StandardColumns.TotalWorkExpected:
      return itemMetricProps.totalWorkExpectedHours
        ? formatAsHoursWithNoRounding(itemMetricProps.totalWorkExpectedHours)
        : '0.00h';

    case StandardColumns.UncertainWork:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.uncertainWorkHours);

    case StandardColumns.UnusedEffortExpected:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.unusedEffortHours);

    case StandardColumns.TotalWorkRange:
      return `${getHoursOrEmptyString(itemMetricProps.totalWorkRange?.lowTotalWorkHours)} - ${getHoursOrEmptyString(
        itemMetricProps.totalWorkRange?.highTotalWorkHours
      )}`;

    case StandardColumns.WorkLimit:
      return workLimitHours ? formatAsHoursWithNoRounding(workLimitHours) : null;

    case StandardColumns.WorkLimitDelta: {
      return itemMetricProps.workLimitDeltaHours
        ? formatAsHoursWithNoRounding(itemMetricProps.workLimitDeltaHours)
        : null;
    }

    case StandardColumns.TimesheetScheduled:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.timesheetScheduled);

    case StandardColumns.TotalWorkLow:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.lowTotalWorkHours);

    case StandardColumns.TotalWorkHigh:
      return getHoursOrEmptyStringWithNoRounding(itemMetricProps.highTotalWorkHours);

    case StandardColumns.TotalWorkScheduled:
      return itemMetricProps.totalWorkScheduled
        ? formatAsHoursWithNoRounding(itemMetricProps.totalWorkScheduled)
        : '0.00h';

    case StandardColumns.ProjectCountTotal:
      return getCountOrEmptyString(itemMetricProps.projectTotalCount);
    case StandardColumns.ProjectCountScheduled:
      return getCountOrEmptyString(itemMetricProps.projectScheduledCount);
    case StandardColumns.ProjectCountDone:
      return getCountOrEmptyString(itemMetricProps.projectDoneCount);
    case StandardColumns.ProjectCountOnHold:
      return getCountOrEmptyString(itemMetricProps.projectOnHoldCount);
    case StandardColumns.TaskCountTotal:
      return getCountOrEmptyString(itemMetricProps.taskTotalCount);
    case StandardColumns.TaskCountActive:
      return getCountOrEmptyString(itemMetricProps.taskActiveCount);
    case StandardColumns.TaskCountDone:
      return getCountOrEmptyString(itemMetricProps.taskDoneCount);
    case StandardColumns.TaskCountOnHold:
      return getCountOrEmptyString(itemMetricProps.taskOnHoldCount);
    case StandardColumns.AssignmentCountTotal:
      return getCountOrEmptyString(itemMetricProps.assignmentTotalCount);
    case StandardColumns.AssignmentCountActive:
      return getCountOrEmptyString(itemMetricProps.assignmentActiveCount);
    case StandardColumns.AssignmentCountDone:
      return getCountOrEmptyString(itemMetricProps.assignmentDoneCount);

    default:
      return null;
  }
};

export const useItemMetricsDisplay = (itemMetric: ItemMetrics | undefined, itemMetricCell: Columns) => {
  const { formatLocalDate } = useLocalizedFormats();
  const { workLimit } = useSelector((state) => getWorkLimitValuesForItemId(state, itemMetric?.item.id || 0));
  const itemMetricProps = getItemMetricValueProps(itemMetric);

  const workLimitHours = workLimit ? convertSecondsToHours(workLimit) : undefined;

  const itemMetricDisplay = getItemMetricValueForColumn({
    column: itemMetricCell,
    displayEmptyStringInsteadOfCount: !itemMetric,
    formatLocalDate,
    itemMetricProps,
    workLimitHours,
  });

  return itemMetricDisplay;
};
