import { useSelector } from 'react-redux';

import { ScheduleDirective } from 'daos/enums';
import { getHasPredecessors, getHasSuccessors } from 'redux/entities/selectors/dependency';
import { getItemForId } from 'redux/entities/selectors/item';
import { getSchedulingLimitsForItemId } from 'redux/entities/selectors/scheduling_limit';

export const useIconsForItem = (itemId: number) => {
  const item = useSelector((state) => getItemForId(state, itemId));
  const hasPredecessors = useSelector((state) => getHasPredecessors(state, itemId));
  const hasSuccessors = useSelector((state) => getHasSuccessors(state, itemId));
  const schedulingLimits = useSelector((state) => getSchedulingLimitsForItemId(state, itemId));

  return {
    item: item,
    isScheduleLate: !!item?.late,
    asapStatus: item?.scheduleDirective ?? ScheduleDirective.NORMAL,
    hasSuccessorOrPredecessor: hasPredecessors || hasSuccessors,
    schedulingLimits: schedulingLimits.length > 0,
  };
};
