import classNames from 'classnames';
import { useState, useCallback, Dispatch } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import MultiSelectModal from 'containers/shared/multi_select_modal';
import {
  getGridColumnsDisplayName,
  resetAvailableOptions,
} from 'containers/shared/multi_select_modal/multi_select_helpers';
import { PackageStatus } from 'daos/enums';
import { UserWorkspaceSettingsDao } from 'daos/user_workspace_settings';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { DataGridViewType } from 'features/common/data_grid/enums';
import { getFilteredOptionsOnGroupCapacity } from 'features/common/data_grid/helpers';
import {
  getDataGridAvailableColumnsForItemType,
  getDataGridItemTypeForPackageStatusAndViewType,
  getDataGridSelectedColumnsForPackageStatus,
  getGridColumnSettingFieldNameForPackageStatusAndGridItemType,
} from 'features/common/data_grid/selectors';
import { setAvailableColumnsForItemType, setSelectedColumnsForItemType } from 'features/common/data_grid/slice';
import { useColumnsAndActiveFieldsByItemType } from 'features/common/item_column/use_item_column_selection_list';
import { LpIcon, lineColumnsLight } from 'features/common/lp_icon';
import { SelectionList } from 'features/common/selection_list/types';
import TimesheetExportCustomColumnModal from 'features/common/timesheet/timesheet_export/timesheet_custom_column_modal';
import { useHasFeature } from 'hooks/use_has_feature';
import { awaitRequestFinish } from 'lib/api';
import { FeatureFlag } from 'lib/feature_flags';
import './index.scss';

interface ItemGridColumnSelectProps {
  initialGridColumns: ReadonlyArray<SelectionList>;
  onClose: () => void;
  packageStatus: PackageStatus | null | undefined;
  dataGridViewType: DataGridViewType;
}

interface WidgetGridColumnSelectProps {
  allGridColumns: ReadonlyArray<SelectionList>;
  initialGridColumns: ReadonlyArray<SelectionList>;
  onClose: () => void;
  selectedGridColumns: ReadonlyArray<SelectionList>;
  setWidgetColumnsSelected: Dispatch<ReadonlyArray<SelectionList>>;
  updateSelectedOptions?: (updatedColumns: Array<SelectionList>) => void;
  availableTitle?: string;
  selectedTitle?: string;
}

interface WidgetGridColumnSelectorButtonProps {
  allGridColumns: ReadonlyArray<SelectionList>;
  disabled?: boolean;
  selectedGridColumns: ReadonlyArray<SelectionList>;
  setSelectedGridColumns: Dispatch<ReadonlyArray<SelectionList>>;
  widgetColumns: ReadonlyArray<SelectionList>;
}

const ItemGridColumnSelectModal = ({
  initialGridColumns,
  onClose,
  packageStatus,
  dataGridViewType,
}: ItemGridColumnSelectProps) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const hasGroupCapacityEnabled = useHasFeature(FeatureFlag.groupCapacity);

  const dataGridItemType = useSelector((state) =>
    getDataGridItemTypeForPackageStatusAndViewType(state, packageStatus, dataGridViewType)
  );

  const allColumnsByItemType = useColumnsAndActiveFieldsByItemType({ forPPPGrid: true });

  const availableColumns = useSelector((state) =>
    getDataGridAvailableColumnsForItemType(state, packageStatus, dataGridViewType)
  ).filter((col) => col.id !== StandardColumns.DateRange);

  const selectedColumns = useSelector((state) =>
    dataGridItemType
      ? getDataGridSelectedColumnsForPackageStatus(
          state,
          packageStatus,
          allColumnsByItemType[dataGridItemType],
          dataGridViewType
        )
      : []
  );

  const availableColumnsAfterReset = resetAvailableOptions(
    [
      ...getFilteredOptionsOnGroupCapacity(availableColumns, hasGroupCapacityEnabled),
      ...getFilteredOptionsOnGroupCapacity(selectedColumns, hasGroupCapacityEnabled),
    ],
    [...initialGridColumns]
  );

  const handleUpdateForColumns = useCallback(
    (availableOptions: Array<SelectionList>, selectedOptions: Array<SelectionList>) => {
      if (dataGridItemType) {
        dispatch(setAvailableColumnsForItemType({ itemType: dataGridItemType, availableColumns: availableOptions }));
        dispatch(setSelectedColumnsForItemType({ itemType: dataGridItemType, selectedColumns: selectedOptions }));

        const settingFieldName = getGridColumnSettingFieldNameForPackageStatusAndGridItemType(
          packageStatus,
          dataGridItemType
        );

        if (settingFieldName) {
          const { uuid } = dispatch(
            UserWorkspaceSettingsDao.update(
              {
                organizationId,
                workspaceId,
                settingFieldName,
              },
              selectedOptions.map((col) => col.id)
            )
          );

          dispatch(awaitRequestFinish(uuid, {}));
        }
      }

      onClose();
    },
    [dataGridItemType, dispatch, onClose, organizationId, packageStatus, workspaceId]
  );

  return (
    <MultiSelectModal
      closeModal={onClose}
      initAvailableOptions={getFilteredOptionsOnGroupCapacity(availableColumns, hasGroupCapacityEnabled)}
      initSelectedOptions={getFilteredOptionsOnGroupCapacity(selectedColumns, hasGroupCapacityEnabled)}
      onCloseAction={handleUpdateForColumns}
      resetColumnsOptions={{
        selected: initialGridColumns,
        available: availableColumnsAfterReset,
      }}
    />
  );
};

export const WidgetGridColumnSelectModal = ({
  allGridColumns,
  initialGridColumns,
  onClose,
  selectedGridColumns,
  setWidgetColumnsSelected,
  updateSelectedOptions,
  availableTitle,
  selectedTitle,
}: WidgetGridColumnSelectProps) => {
  const availableColumnsAfterReset = resetAvailableOptions([...allGridColumns], [...initialGridColumns]);
  const initialAvailableColumns = resetAvailableOptions([...allGridColumns], [...selectedGridColumns]);

  const handleUpdateForColumns = useCallback(
    (_: Array<SelectionList>, selectedOptions: Array<SelectionList>) => {
      const selectedOptionsWithDisplayNames = getGridColumnsDisplayName(selectedOptions);
      setWidgetColumnsSelected(selectedOptionsWithDisplayNames);
      updateSelectedOptions?.(selectedOptionsWithDisplayNames);

      onClose();
    },
    [onClose, setWidgetColumnsSelected, updateSelectedOptions]
  );

  return (
    <MultiSelectModal
      closeModal={onClose}
      initAvailableOptions={initialAvailableColumns}
      initSelectedOptions={selectedGridColumns}
      onCloseAction={handleUpdateForColumns}
      availableTitle={availableTitle}
      selectedTitle={selectedTitle}
      resetColumnsOptions={{
        selected: initialGridColumns,
        available: availableColumnsAfterReset,
      }}
    />
  );
};

const BaseSelectorButton = ({
  buttonText = 'Columns',
  className = '',
  disabled = false,
  gridColumnModal,
  selectedColumnsChanged,
}: {
  buttonText?: string;
  className?: string;
  disabled?: boolean;
  gridColumnModal: (handleClose: () => void) => JSX.Element;
  selectedColumnsChanged: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  return (
    <>
      <Button
        className={classNames('manage-columns-button', className, {
          'manage-columns-button--modified': selectedColumnsChanged,
        })}
        disabled={disabled}
        onClick={handleOpen}
      >
        <LpIcon icon={lineColumnsLight} />
        {buttonText}
      </Button>

      {isOpen && gridColumnModal(handleClose)}
    </>
  );
};

export const ItemGridColumnSelectorButton = ({
  initialGridColumns,
  packageStatus,
  dataGridViewType,
}: {
  initialGridColumns: ReadonlyArray<SelectionList>;
  packageStatus: PackageStatus | null | undefined;
  dataGridViewType: DataGridViewType;
}) => {
  const allColumnsByItemType = useColumnsAndActiveFieldsByItemType({ forPPPGrid: true });
  const dataGridItemType = useSelector((state) =>
    getDataGridItemTypeForPackageStatusAndViewType(state, packageStatus, dataGridViewType)
  );

  const selectedColumns = useSelector((state) =>
    dataGridItemType
      ? getDataGridSelectedColumnsForPackageStatus(
          state,
          packageStatus,
          allColumnsByItemType[dataGridItemType],
          dataGridViewType
        )
      : []
  );

  const gridColumnModal = (onClose: () => void) => (
    <ItemGridColumnSelectModal
      initialGridColumns={initialGridColumns}
      onClose={onClose}
      packageStatus={packageStatus}
      dataGridViewType={dataGridViewType}
    />
  );

  const selectedColumnsChanged = getIsColumnSelectionModified(initialGridColumns, selectedColumns);
  return <BaseSelectorButton gridColumnModal={gridColumnModal} selectedColumnsChanged={selectedColumnsChanged} />;
};

export function getTsExportSelectedColumnsInOrder(
  allSelectionLists: ReadonlyArray<SelectionList>,
  columnIds: ReadonlyArray<string> | undefined
) {
  function findSelectionList(columnId: string) {
    return allSelectionLists.find((selectionList) => selectionList.id === columnId);
  }

  return columnIds?.map(findSelectionList).filter((selection): selection is SelectionList => !!selection) ?? [];
}

export const TimesheetExportGridColumnSelectorButton = ({
  className,
  defaultTsExportGridColumns,
  allTsExportGridColumns,
  selectedTsExportGridColumns,
  updateTimesheetExportGridSelectedColumns,
}: {
  className: string;
  defaultTsExportGridColumns: ReadonlyArray<SelectionList>;
  allTsExportGridColumns: ReadonlyArray<SelectionList>;
  selectedTsExportGridColumns: ReadonlyArray<string> | undefined;
  updateTimesheetExportGridSelectedColumns: (selectedColumns: ReadonlyArray<SelectionList>) => void;
}) => {
  const selectedColumns = getTsExportSelectedColumnsInOrder(allTsExportGridColumns, selectedTsExportGridColumns);

  const activeGridColumns = selectedColumns.length ? selectedColumns : defaultTsExportGridColumns;

  const gridColumnModal = (onClose: () => void) => (
    <TimesheetExportCustomColumnModal
      onClose={onClose}
      defaultTsExportGridColumns={defaultTsExportGridColumns}
      allGridColumns={allTsExportGridColumns}
      selectedGridColumns={activeGridColumns}
      updateTimesheetExportGridSelectedColumns={updateTimesheetExportGridSelectedColumns}
    />
  );

  const selectedColumnsChanged = !isEqual(defaultTsExportGridColumns, activeGridColumns);

  return (
    <BaseSelectorButton
      gridColumnModal={gridColumnModal}
      selectedColumnsChanged={selectedColumnsChanged}
      buttonText={'Customize Columns'}
      className={className}
    />
  );
};

export const WidgetGridColumnSelectorButton = ({
  allGridColumns,
  disabled,
  selectedGridColumns,
  setSelectedGridColumns,
  widgetColumns,
}: WidgetGridColumnSelectorButtonProps) => {
  const selectedColumnsChanged = !isEqual(widgetColumns, selectedGridColumns);

  const gridColumnModal = (onClose: () => void) => (
    <WidgetGridColumnSelectModal
      allGridColumns={allGridColumns}
      initialGridColumns={widgetColumns}
      onClose={onClose}
      selectedGridColumns={selectedGridColumns}
      setWidgetColumnsSelected={setSelectedGridColumns}
    />
  );

  return (
    <BaseSelectorButton
      disabled={disabled}
      gridColumnModal={gridColumnModal}
      selectedColumnsChanged={selectedColumnsChanged}
    />
  );
};

function getIsColumnSelectionModified(
  initialColumns: ReadonlyArray<SelectionList>,
  selectedColumns: ReadonlyArray<SelectionList>
) {
  return !isEqual(
    initialColumns.map((col) => col.id),
    selectedColumns.map((col) => col.id)
  );
}
