import { roundAndTruncateTrailing } from 'lib/display_helpers/format_as_hours';
import { convertHoursToSeconds } from 'lib/helpers';

const MAX_HOURS_IN_A_DAY = 24;
const MAX_DAYS_IN_A_MONTH = 31;
const MAX_LIMIT_HOURS = MAX_HOURS_IN_A_DAY * MAX_DAYS_IN_A_MONTH; // Adding limit support for Groups will change this
const MIN_LIMIT_HOURS = 0.25;

const validateIsNumber = (number: number) => {
  return !isNaN(number);
};

const convertStringToNumber = (inputValue: string) => Number(inputValue);

const removeFormatCharacter = (inputValue: string) => inputValue.replace(/h$/i, '');

const hasHourFormatCharacter = (inputValue: string) => inputValue.endsWith('h') || inputValue.endsWith('H');

const isWithinLimits = (inputValue: number) => {
  return inputValue <= MAX_LIMIT_HOURS && inputValue >= MIN_LIMIT_HOURS;
};

const isGreaterThanOrEqualToZero = (inputValue: number) => {
  return inputValue >= 0;
};

const validateNumberWithHourFormat = (inputValue: string): boolean => {
  const hasHourFormat = hasHourFormatCharacter(inputValue);
  const workLimitNumber = validateIsNumber(convertStringToNumber(removeFormatCharacter(inputValue)));
  return hasHourFormat && workLimitNumber;
};

const validateNumberWithLimits = (inputValue: string) => {
  const valueWithoutFormatCharacter = removeFormatCharacter(inputValue);
  const workLimitNumber = convertStringToNumber(valueWithoutFormatCharacter);
  return isWithinLimits(workLimitNumber) && validateIsNumber(workLimitNumber);
};

const validateNumber = (inputValue: string) => {
  const valueWithoutFormatCharacter = removeFormatCharacter(inputValue);
  const workLimitNumber = convertStringToNumber(valueWithoutFormatCharacter);
  return isGreaterThanOrEqualToZero(workLimitNumber) && validateIsNumber(workLimitNumber);
};

export const formatLimitHoursInputValue = (hasValidUnitOfTime: boolean, inputValue: string) => {
  const numberValue = hasValidUnitOfTime ? inputValue.slice(0, -1) : inputValue;
  return convertHoursToSeconds(roundAndTruncateTrailing(Number(numberValue)));
};

export const validateLimitHours = (inputValue: string) => {
  if (!inputValue) {
    return { isValidLimitHours: false, hasValidUnitOfTime: false };
  }

  const isValidNumber = validateNumberWithLimits(inputValue);
  const isValidNumberHour = validateNumberWithHourFormat(inputValue);

  return { isValidLimitHours: isValidNumber, hasValidUnitOfTime: isValidNumberHour };
};

export const validateHoursAllowsEmptyValue = (inputValue: string) => {
  const isEmptyString = inputValue === '';
  const isValidNumber = isEmptyString || validateNumber(inputValue);
  const isValidNumberHourFormat = validateNumberWithHourFormat(inputValue);

  return { isValidLimitHours: isValidNumber, hasValidUnitOfTime: isValidNumberHourFormat };
};
