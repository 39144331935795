import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

const { resource } = defineModel({
  apiType: 'jiraProjects',
  type: 'JIRA_PROJECT',
});

const { JIRA_PROJECT } = resource;

interface JiraProjectsParams {
  organizationId: number;
  workspaceId: number;
}

interface JiraProjectDetailsParams extends JiraProjectsParams {
  projectId: string;
}

interface JiraQuery {
  oauthCredentialsId: number;
  cloudId: string;
}

interface JiraProjectIssuesCountQuery extends JiraQuery {
  projectIdOrKey: string;
  typeIds: ReadonlyArray<string>;
  statusIds: ReadonlyArray<string>;
  createdAfter: string;
}

interface JiraProjectUserCountQuery extends JiraQuery {
  projectId: string;
}

interface JiraProjectMappingFieldsQuery extends JiraQuery {
  projectIdOrKey: string;
  issueTypeIds: ReadonlyArray<string>;
}

const fetchJiraProjects = (params: JiraProjectsParams, query: JiraQuery) =>
  request(backend.jiraProjects.url(params, { query }), JIRA_PROJECT, {
    method: HttpMethod.GET,
  });

const fetchJiraProjectDetails = (params: JiraProjectDetailsParams, query: JiraQuery) =>
  request(backend.jiraProjectDetails.url(params, { query }), JIRA_PROJECT, {
    method: HttpMethod.GET,
  });

const fetchJiraIssuesCount = (params: JiraProjectsParams, query: JiraProjectIssuesCountQuery) => {
  return request(backend.jiraProjectIssuesCount.url(params, { query }), JIRA_PROJECT, {
    method: HttpMethod.GET,
  });
};

const fetchJiraProjectUserCount = (params: JiraProjectsParams, query: JiraProjectUserCountQuery) => {
  return request(backend.jiraProjectUserCount.url(params, { query }), JIRA_PROJECT, {
    method: HttpMethod.GET,
  });
};

const fetchJiraProjectMappingFields = (params: JiraProjectsParams, query: JiraProjectMappingFieldsQuery) => {
  return request(backend.jiraProjectMappingFields.url(params, { query }), JIRA_PROJECT, {
    method: HttpMethod.GET,
  });
};

export const JiraProjectsDao = {
  fetchJiraProjects,
  fetchJiraProjectDetails,
  fetchJiraIssuesCount,
  fetchJiraProjectMappingFields,
  fetchJiraProjectUserCount,
} as const;
