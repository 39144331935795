import { Button, Image } from 'semantic-ui-react';

import LpModal from 'features/common/modals/lp_modal';
import { SubFolderOrganizationValues } from 'features/jira_project/modal/types';

export const SubfolderExampleModal = ({
  exampleSelected,
  setExampleSelected,
}: {
  exampleSelected: SubFolderOrganizationValues;
  setExampleSelected: React.Dispatch<React.SetStateAction<SubFolderOrganizationValues | undefined>>;
}) => {
  const headerContent = (
    <div>
      Example - {exampleSelected.name}:
      <div className={'jira-project-modal__content-sync-settings-subfolder-organization-example-modal-description'}>
        {exampleSelected.subtitle}
      </div>
    </div>
  );

  const closeExampleModal = () => setExampleSelected(undefined);

  return (
    <LpModal
      size={'tiny'}
      header={headerContent}
      content={<Image src={exampleSelected.image} size={'huge'} centered />}
      actions={<Button onClick={closeExampleModal}>Close</Button>}
    />
  );
};
