export const lpErrorText = {
  certContent: 'SSO Identity Provider Certificate is required field',
  color: 'Please select a color',
  companyName: 'Please enter your Company Name',
  description: 'Description is a required field',
  email_ascii: 'Please enter a valid email address.   Must contain only ASCII characters',
  email: 'Please enter a valid email address',
  fieldType: 'Field type is required',
  firstName: 'First Name is a required field',
  HighFieldError: 'Please enter high hours remaining as a decimal number, for instance 22 or 22.2',
  HoursFieldError: 'Please enter hours worked as a decimal number, for instance 1 or 1.5',
  issuerURL: 'Issuer URL is required field',
  itemDestinationId: 'Destination is a required field',
  lastName: 'Last Name is a required field',
  loggedTimeCostCodeRequired: 'Please select a Cost Code to categorize the type of progress logged',
  LowFieldError: 'Please enter low hours remaining as a decimal number, for instance .7 or 1',
  LowHigherThanHighError: 'Low estimate should be less than high estimate',
  mustHaveBothStartAndFinish: 'Must have both start and finish times',
  name: 'Name is required',
  numberOfResources: 'Number of Resources is required',
  organizationName: 'Please enter an Organization Name',
  packageName: 'Please enter a Package name',
  passwordConfirmation: 'Password confirmation is a required field',
  passwordLength: 'Password must be at least 8 characters',
  passwordMatch: 'Passwords do not match',
  phoneNumber: 'Please enter a valid Business Phone Number',
  rateSheetsBillingRateMinimum: 'Negative Billing Rates are not allowed',
  rateSheetsBillingRateRequired: 'Billing Rate must be a number',
  signUpKey: 'Signup Key is a required field',
  singleSignOnUrl: 'Sign-on URL is required field',
  somethingWentWrong: 'Something went wrong. Please try again',
  startTimeLaterThanFinishTime: 'Start time cannot be later than end time',
  subject: 'Subject is a required field',
  targetStartDate: 'Target Start Date must be before Target Finish',
  termsOfService: 'Please accept the Terms of Service',
  timesheetCellCostCodeRequired: 'Select a Cost Code by clicking on your Assignment name',
  timesheetCellTimeFormat: 'Use numbers like 8, 30m, 1.5h or 3d',
  timesheetLockError: 'This timesheet is locked through',
  useOnCheckBox: 'Please check at least one checkbox',
  userRequired: 'User is required',
  workspaceName: 'Please enter a Workspace Name.',
  workspaceNeeded: 'You must select at least one Workspace to invite.',
  hoursLimitExceeded: 'Hours entered exceeds the field limit.',
  requiredField: (fieldName: string) => `${fieldName} is required`,
  requiredFields: (fieldName: string) => `${fieldName} are required`,
};
