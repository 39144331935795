import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ASAPLabel } from 'containers/shared/notice_icons/asap_label';
import { DependencyLabel } from 'containers/shared/notice_icons/dependency_label';
import { RiskLabel } from 'containers/shared/notice_icons/risk_label';
import { SchedulingLimitsLabel } from 'containers/shared/notice_icons/scheduling_limits_label';
import { WaitingLabel } from 'containers/shared/notice_icons/waiting_label';
import { ScheduleDirective } from 'daos/enums';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { useIconsForItem } from 'hooks/use_icons_for_item';
import { getWorkLimitValuesForItemId } from 'redux/entities/selectors/work_limits_values';

interface SchedulingLabelProps {
  itemId: number;
  ignoreRisk?: boolean;
  ignoreWorkLimit?: boolean;
  showScheduleLimit?: boolean;
  waiting?: boolean;
}

const SchedulingLabels = ({
  itemId,
  ignoreRisk = false,
  ignoreWorkLimit = false,
  waiting,
  showScheduleLimit = true,
}: SchedulingLabelProps) => {
  const history = useHistory();
  const { hasWorkLimitAlert } = useSelector((state) => getWorkLimitValuesForItemId(state, itemId));

  const openItemPanelSchedulingTab = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      history.push(`#panelId=${itemId}&panelSection=${TabNames.Scheduling}Tab`);
    },
    [history, itemId]
  );

  const { asapStatus, hasSuccessorOrPredecessor, isScheduleLate } = useIconsForItem(itemId);

  const showLate = !ignoreRisk && isScheduleLate;
  const showWorkLimitAlert = !ignoreWorkLimit && hasWorkLimitAlert;

  const schedulingLabels = useMemo(() => {
    const labels = [];

    if (showScheduleLimit) {
      labels.push(<SchedulingLimitsLabel key={'isScheduleLimitActive'} itemId={itemId} />);
    }

    if (hasSuccessorOrPredecessor) {
      labels.push(<DependencyLabel key={'hasSuccessorOrPredecessor'} onClick={openItemPanelSchedulingTab} />);
    }

    if (showLate || showWorkLimitAlert) {
      labels.push(<RiskLabel onClick={openItemPanelSchedulingTab} key={'isScheduleLate'} />);
    }

    if (waiting) {
      labels.push(<WaitingLabel key={'isWaiting'} onClick={openItemPanelSchedulingTab} />);
    }

    const hasAsapStatus = asapStatus !== null;
    const hasRushedStatus = hasAsapStatus && asapStatus !== ScheduleDirective.NORMAL;
    if (hasRushedStatus) {
      labels.push(
        <ASAPLabel onSchedulingClick={openItemPanelSchedulingTab} scheduleDirective={asapStatus} key={'asapStatus'} />
      );
    }

    return labels;
  }, [
    asapStatus,
    itemId,
    hasSuccessorOrPredecessor,
    openItemPanelSchedulingTab,
    showLate,
    showWorkLimitAlert,
    showScheduleLimit,
    waiting,
  ]);

  return <>{schedulingLabels}</>;
};

export default SchedulingLabels;
