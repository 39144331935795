import { useFormikContext } from 'formik';
import { SyntheticEvent } from 'react';

import { JiraIntegration, JiraOauthAccount } from 'daos/model_types';
import { JiraProjectModalTableDeleteCell } from 'features/jira_project/modal/common/jira_project_modal_table_delete_cell';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTablePicklistCell } from 'features/jira_project/modal/common/jira_project_modal_table_picklist_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectModalPicklist } from 'features/jira_project/modal/sections/jira_project_modal_picklist';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  JiraProjectModalPicklistData,
} from 'features/jira_project/modal/types';

const getJiraIntegrationsDropdownOptions = (
  jiraIntegrations: ReadonlyArray<JiraIntegration>
): Array<JiraProjectModalPicklistData> =>
  jiraIntegrations.map((jiraIntegration) => ({
    id: jiraIntegration.id,
    name: jiraIntegration.name,
    disabled: false,
  }));

const getJiraEmailDropdownOptions = (
  jiraIntegrationInfo: ReadonlyArray<JiraOauthAccount>
): Array<JiraProjectModalPicklistData> =>
  jiraIntegrationInfo.map((jiraIntegration) => ({
    id: jiraIntegration.oauthCredentialsId,
    name: jiraIntegration.email,
    disabled: false,
  }));

export const JiraCredentialsCreateTable = ({
  jiraOAuthAccounts,
  jiraIntegrations,
}: {
  jiraOAuthAccounts: ReadonlyArray<JiraOauthAccount>;
  jiraIntegrations: ReadonlyArray<JiraIntegration>;
}) => {
  const { setFieldValue, values } = useFormikContext<JiraProjectModalFormValues>();

  const oauthCredentialsId = values[JiraProjectModalFormFields.OauthCredentialsId];

  const handleDeleteButtonClick = (field: JiraProjectModalFormFields) => {
    switch (field) {
      case JiraProjectModalFormFields.OauthCredentialsId:
        setFieldValue(JiraProjectModalFormFields.OauthCredentialsId, '');
        setFieldValue(JiraProjectModalFormFields.CloudId, '');
        return;
      case JiraProjectModalFormFields.CloudId:
        setFieldValue(JiraProjectModalFormFields.CloudId, '');
        return;
      default:
        return;
    }
  };

  const removeOauthCredentials = () => {
    setFieldValue(JiraProjectModalFormFields.OauthCredentialsId, '');
    setFieldValue(JiraProjectModalFormFields.CloudId, '');
  };

  const setOauthCredentials = (data: { value: string }) => {
    setFieldValue(JiraProjectModalFormFields.OauthCredentialsId, data.value);
  };

  const handleOauthCredentialsChange = (_: SyntheticEvent<HTMLElement>, data: { value: string }) => {
    if (!data.value) {
      removeOauthCredentials();
    } else {
      setOauthCredentials(data);
    }
  };

  return (
    <>
      <JiraProjectModalTableRow>
        <JiraProjectModalTableNameCell content="Email Provided*" />
        <JiraProjectModalTablePicklistCell
          picklist={
            <JiraProjectModalPicklist
              placeholder="--Select an Email--"
              picklist={getJiraEmailDropdownOptions(jiraOAuthAccounts)}
              renderIcon={false}
              name={JiraProjectModalFormFields.OauthCredentialsId}
              onChange={handleOauthCredentialsChange}
            />
          }
        />
        <JiraProjectModalTableDeleteCell
          enabled={!!oauthCredentialsId}
          onClick={() => handleDeleteButtonClick(JiraProjectModalFormFields.OauthCredentialsId)}
        />
      </JiraProjectModalTableRow>
      <JiraProjectModalTableRow>
        <JiraProjectModalTableNameCell content="Available Jira Instance*" />
        <JiraProjectModalTablePicklistCell
          picklist={
            <JiraProjectModalPicklist
              placeholder="--Select an Instance--"
              picklist={getJiraIntegrationsDropdownOptions(jiraIntegrations)}
              renderIcon={false}
              disabled={!oauthCredentialsId}
              name={JiraProjectModalFormFields.CloudId}
            />
          }
        />
        <JiraProjectModalTableDeleteCell
          enabled={!!values[JiraProjectModalFormFields.CloudId]}
          onClick={() => handleDeleteButtonClick(JiraProjectModalFormFields.CloudId)}
        />
      </JiraProjectModalTableRow>
    </>
  );
};
