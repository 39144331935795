import { SetStateAction } from 'react';

import { FieldMappingValue } from 'features/jira_project/modal/types';

export const addFieldMappingRow = ({
  data,
  rows,
  setRows,
}: {
  data: Array<FieldMappingValue>;
  rows: Set<FieldMappingValue>;
  setRows: (value: SetStateAction<Set<FieldMappingValue>>) => void;
}) => {
  const nextItem = data.find((item) => !Array.from(rows).some((addedItem) => addedItem.id === item.id));

  if (nextItem) {
    setRows(new Set([...rows, nextItem]));
  }
};

export const deleteFieldMappingRow = ({
  row,
  rows,
  setRows,
}: {
  row: FieldMappingValue;
  rows: Set<FieldMappingValue>;
  setRows: (value: React.SetStateAction<Set<FieldMappingValue>>) => void;
}) => {
  setRows(new Set(Array.from(rows).filter((item) => item.id !== row.id)));
};

export const shouldDisableAddRowButton = ({
  data,
  rows,
}: {
  data: Array<FieldMappingValue>;
  rows: Set<FieldMappingValue>;
}) => data.every((item) => Array.from(rows).some((addedItem) => addedItem.id === item.id));
