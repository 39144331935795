import { ASAPLabel } from 'containers/shared/notice_icons/asap_label';
import { DependencyLabel } from 'containers/shared/notice_icons/dependency_label';
import { RiskLabel } from 'containers/shared/notice_icons/risk_label';
import { SchedulingLimitsLabelRenderer } from 'containers/shared/notice_icons/scheduling_limits_label';
import { ScheduleDirective } from 'daos/enums';

export const SchedulingLabelsRenderer = ({
  showSchedulingLimitsLabel,
  showDependencyLabel,
  showRiskLabel,
  asapStatus,
}: {
  showSchedulingLimitsLabel: boolean;
  showDependencyLabel: boolean;
  showRiskLabel: boolean;
  asapStatus: ScheduleDirective;
}) => (
  <>
    {showSchedulingLimitsLabel && <SchedulingLimitsLabelRenderer />}
    {showDependencyLabel && <DependencyLabel />}
    {showRiskLabel && <RiskLabel />}
    {asapStatus !== ScheduleDirective.NORMAL && <ASAPLabel scheduleDirective={asapStatus} />}
  </>
);
