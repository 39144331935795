import classNames from 'classnames';
import { debounce, isEmpty, isFunction } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, CheckboxProps } from 'semantic-ui-react';

import FolderStatusIcon from 'containers/shared/folder_status_icon';
import ItemIcon from 'containers/shared/item_icon';
import { ItemPickerCheckbox } from 'containers/shared/item_picker/item_picker_checkbox';
import {
  pickerOrMoveFilteredItemFetchUuid,
  usePickerOrMoveFetchRequests,
  usePickerOrMoveFilterQueryString,
  usePickerOrMoveTreeTraversal,
} from 'containers/shared/move_and_picker_shared_helpers';
import { packageIcon } from 'containers/shared/move_modal/item_move_helpers';
import { ItemType, PackageStatus, Permission, SchedulingType } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { FolderStatus } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import IncludeDoneButton from 'features/common/inputs/include_done_button';
import LpSearchInputControlled from 'features/common/inputs/lp_search_input/lp_search_input_controlled';
import { getItemPickerDataOrderedByPriority, getItemPickerSelection } from 'features/common/item_picker/selectors';
import {
  clearItemPicker,
  selectItemPickerRow,
  unselectAllItemPickerRows,
  unselectItemPickerRow,
  updateItemPickerStore,
} from 'features/common/item_picker/slice';
import { arrowAltUpSolid, LpIcon } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { useItemPanelParams } from 'features/item_panel/use_item_panel_params';
import { useDirtyInputUpdate } from 'hooks/use_dirty_input_update';
import { useHasFeature } from 'hooks/use_has_feature';
import { usePrevious } from 'hooks/use_previous';
import { awaitRequestFinish } from 'lib/api';
import { SuccessPayload } from 'lib/api/types';
import { KEYDOWN_DEBOUNCE_MILLIS } from 'lib/constants';
import { FeatureFlag } from 'lib/feature_flags';
import { isTask, isWorkspaceRoot } from 'redux/entities/selectors/item';
import { getCurrentWorkspace } from 'redux/entities/selectors/workspace';
import { slate700 } from 'style/variables';

import ItemPickerParent from './parent';

import './item_picker.scss';

interface ItemPickerModalProps {
  allowedItemTypesToSelect?: ReadonlySet<ItemType>;
  allowMultiSelect?: boolean;
  defaultPackageStatus?: PackageStatus | null;
  disableMoveUpTreeAtItemId?: number;
  disableMoveUpTreeAtPackageCollection?: boolean;
  enablePackageStatusSelection?: boolean;
  enableParentSelection?: boolean;
  fetchItemsOnFirstRender?: boolean;
  filterItemTypes: Array<ItemType>;
  initParentItem: Item;
  isTemplateDependency?: boolean;
  onCancel: () => void;
  onSelect: (itemIds: Array<number>, packageStatus: PackageStatus | null) => void;
  onSelectText?: string;
  requiredAccess: Permission | ((item: Item) => Permission);
  restrictedItemIds?: Array<number>;
}

const ItemPickerModal = ({
  allowedItemTypesToSelect,
  allowMultiSelect = false,
  defaultPackageStatus,
  disableMoveUpTreeAtItemId,
  disableMoveUpTreeAtPackageCollection,
  enablePackageStatusSelection,
  enableParentSelection,
  fetchItemsOnFirstRender,
  filterItemTypes,
  initParentItem,
  isTemplateDependency = false,
  onCancel,
  onSelect,
  onSelectText = 'Link',
  requiredAccess,
  restrictedItemIds = [],
}: ItemPickerModalProps) => {
  const dispatch = useDispatch();
  const hasGroupCapacityEnabled = useHasFeature(FeatureFlag.groupCapacity);
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const workspaceRootId = useSelector(getCurrentWorkspace)?.workspaceRoot.id;
  const { itemPanelId } = useItemPanelParams();

  const pickerData = useSelector(getItemPickerDataOrderedByPriority);
  const selection = useSelector(getItemPickerSelection);

  const [packageStatus, setPackageStatus] = useState<PackageStatus | null>(defaultPackageStatus ?? null);
  const [atWorkspaceRoot, setAtWorkspaceRoot] = useState(
    initParentItem.itemType === ItemType.WORKSPACE_ROOTS && !packageStatus
  );
  const [filterValue, setFilterValue] = useState('');
  const [includeDone, setIncludeDone] = useState(false);
  const [parent, setParent] = useState<Item>(initParentItem);
  const setDebouncedPickerFilterValue = useRef<(value: React.SetStateAction<string>) => void>(
    debounce((value: React.SetStateAction<string>) => setFilterValue(value), KEYDOWN_DEBOUNCE_MILLIS)
  );

  const [isFetchingData, setIsFetchingData] = useState(false);

  const folderStatusFilter = includeDone
    ? [FolderStatus.ACTIVE, FolderStatus.ON_HOLD, FolderStatus.DONE]
    : [FolderStatus.ACTIVE, FolderStatus.ON_HOLD];

  const taskStatusSchedulingTypes = includeDone
    ? [SchedulingType.Scheduled, SchedulingType.Unscheduled, SchedulingType.Done]
    : [SchedulingType.Scheduled, SchedulingType.Unscheduled];

  const numberItemsSelected = Object.keys(selection).length;
  const workspaceSelected = workspaceRootId ? selection[workspaceRootId] : false;

  const unSelectAll = useCallback(() => {
    dispatch(unselectAllItemPickerRows());
  }, [dispatch]);

  const handleIncludeDoneItems = useCallback(() => {
    setIncludeDone((prevIncludeDone) => !prevIncludeDone);
  }, []);

  const filterQueryString = usePickerOrMoveFilterQueryString({
    filterItemTypes,
    filterValue,
    folderStatus: folderStatusFilter,
    packageStatus,
    parent,
    schedulingType: taskStatusSchedulingTypes,
  });

  const filteredItemFetch = useCallback(() => {
    if (atWorkspaceRoot) {
      return;
    }

    setIsFetchingData(true);
    const uuid = pickerOrMoveFilteredItemFetchUuid({
      dispatch,
      filterQueryString,
      organizationId,
      workspaceId,
    });

    dispatch(
      awaitRequestFinish<ReadonlyArray<Item>>(uuid, {
        onSuccess: (response) => {
          const items = response.entities.items;
          dispatch(updateItemPickerStore(items));
          unSelectAll();
        },
        onFinish: () => setIsFetchingData(false),
      })
    );
  }, [atWorkspaceRoot, dispatch, filterQueryString, organizationId, unSelectAll, workspaceId]);

  useEffect(() => {
    if (fetchItemsOnFirstRender) {
      filteredItemFetch();
    }
    // Run this function only when picker type changes or on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchItemsOnFirstRender]);

  const prevIncludeDone = usePrevious(includeDone);
  const prevFilterValue = usePrevious(filterValue);

  const inputFilterChanged = filterValue !== prevFilterValue;
  const includeDoneChanged = prevIncludeDone !== includeDone;

  useEffect(() => {
    if (!isFetchingData && (inputFilterChanged || includeDoneChanged)) {
      filteredItemFetch();
    }
  }, [filteredItemFetch, includeDoneChanged, inputFilterChanged, isFetchingData]);

  useEffect(() => {
    if (parent && parent.packageStatus) {
      setPackageStatus(parent.packageStatus);
    }
  }, [parent]);

  const updateSelectedItem = useCallback(
    (_: React.SyntheticEvent, { checked, value }: CheckboxProps) => {
      if (value) {
        if (checked) {
          const numberValue = Number(value);
          if (!isNaN(numberValue)) {
            dispatch(selectItemPickerRow(numberValue));
          } else if (Object.keys(packageStatusDisplayNameByPackageStatus).includes(String(value))) {
            setPackageStatus(value as PackageStatus);

            if (workspaceRootId) {
              dispatch(selectItemPickerRow(workspaceRootId));
            }
          }
        } else {
          if (Object.keys(packageStatusDisplayNameByPackageStatus).includes(String(value)) && workspaceRootId) {
            setPackageStatus(null);
            dispatch(unselectItemPickerRow(workspaceRootId));
          } else {
            dispatch(unselectItemPickerRow(Number(value)));
          }
        }
      }
    },
    [dispatch, workspaceRootId]
  );

  const getParentForMove = useCallback(
    (itemId: number) => {
      const { uuid } = dispatch(ItemDao.fetch({ organizationId, workspaceId, itemId }));

      dispatch(
        awaitRequestFinish<Item>(uuid, {
          onSuccess: (response) => {
            const items = response.entities.items;
            setParent(response.data);
            dispatch(updateItemPickerStore(items));
            unSelectAll();
          },
        })
      );
    },
    [dispatch, organizationId, unSelectAll, workspaceId]
  );

  const onAwaitMoveUp = (response: SuccessPayload<ReadonlyArray<Item>>, parentId: number) => {
    const items = response.entities.items;
    if (items) {
      const parent = items[parentId];
      parent && setParent(parent);
      dispatch(updateItemPickerStore(items));
      unSelectAll();
    } else {
      getParentForMove(parentId);
    }
  };

  const onAwaitMoveDown = (response: SuccessPayload<ReadonlyArray<Item>>, parent: Item) => {
    const items = response.entities.items;
    setParent(parent);
    dispatch(updateItemPickerStore(items));
    unSelectAll();
  };

  const { fetchPackages, fetchItemsForParent } = usePickerOrMoveFetchRequests({
    dispatch,
    filterItemTypes,
    folderStatus: folderStatusFilter,
    organizationId,
    parent,
    schedulingType: taskStatusSchedulingTypes,
    workspaceId,
  });

  const { isDirty, inputValue, setInputValue, setIsDirty, onChange, clearInput } = useDirtyInputUpdate({
    onChangeCallback: useCallback((value: string) => {
      setDebouncedPickerFilterValue.current(value);
    }, []),
  });

  const { moveUpTree, moveDownTree } = usePickerOrMoveTreeTraversal({
    atWorkspaceRoot,
    dispatch,
    fetchRequests: { fetchItemsForParent, fetchPackages },
    onAwaitMoveDown,
    onAwaitMoveUp,
    packageStatus,
    parentItemId: parent?.parent?.id,
    setState: {
      setAtWorkspaceRoot,
      setFilterValue,
      setInputValue,
      setIsDirty,
      setPackageStatus,
      setIsFetchingData,
    },
  });

  const handlePickerClose = useCallback(() => {
    onCancel();
    dispatch(clearItemPicker());
  }, [dispatch, onCancel]);

  const handlePackageStatusClick = useCallback(
    (status: PackageStatus) => {
      setPackageStatus(status);
      setAtWorkspaceRoot(false);
      moveDownTree(parent, status);
    },
    [moveDownTree, parent]
  );

  const pkgStatusButtons = useMemo(() => {
    return [
      PackageStatus.SCHEDULED,
      PackageStatus.BACKLOG,
      PackageStatus.ARCHIVED,
      ...(hasGroupCapacityEnabled ? [PackageStatus.CAPACITY] : []),
    ].map((status) => {
      return (
        <span className="lp-item-picker__content-table-items-row" key={status}>
          {enablePackageStatusSelection && (
            <Checkbox
              disabled={(!!packageStatus && packageStatus !== status) || (workspaceSelected && !packageStatus)}
              onChange={updateSelectedItem}
              value={status}
            />
          )}
          <Button
            className="lp-item-picker__content-table-items-package"
            basic
            fluid
            onClick={() => handlePackageStatusClick(status)}
          >
            <LpIcon
              className="lp-item-picker__content-table-items-icon"
              size="lg"
              icon={status && packageIcon(status)}
              color={slate700}
            />

            {packageStatusDisplayNameByPackageStatus[status]}
          </Button>
        </span>
      );
    });
  }, [
    enablePackageStatusSelection,
    handlePackageStatusClick,
    packageStatus,
    updateSelectedItem,
    workspaceSelected,
    hasGroupCapacityEnabled,
  ]);

  const itemTargetButtons = useMemo(() => {
    const parentId = parent?.id;
    if (parentId) {
      pickerData.delete(parentId);
    }

    const itemButtons: Array<JSX.Element> = [];

    pickerData.forEach((item: Item) => {
      const forceDisableFlag = restrictedItemIds.includes(item.id);

      const requiredAccessPermission = isFunction(requiredAccess) ? requiredAccess(item) : requiredAccess;

      return itemButtons.push(
        <span className="lp-item-picker__content-table-items-row" key={item.id}>
          <ItemPickerCheckbox
            item={item}
            allowMultiSelect={allowMultiSelect}
            allowedItemTypesToSelect={allowedItemTypesToSelect}
            updateSelectedItem={updateSelectedItem}
            requiredAccess={requiredAccessPermission}
            forceDisableFlag={forceDisableFlag}
          />

          <Button
            basic
            fluid
            className={classNames(
              'icon',
              item.id === Number(itemPanelId) || forceDisableFlag ? 'current-item-disable' : ''
            )}
            disabled={isTask(item)}
            onClick={() => moveDownTree(item)}
          >
            <ItemIcon size="lg" itemId={item.id} className="lp-item-picker__content-table-items-icon" />
            <FolderStatusIcon className="lp-item-picker__content-table-items-icon" folderStatus={item.folderStatus} />
            <span className="lp-item-picker__content-table-items-row-name">{item.name}</span>
          </Button>
        </span>
      );
    });
    return itemButtons;
  }, [
    allowMultiSelect,
    allowedItemTypesToSelect,
    itemPanelId,
    moveDownTree,
    parent?.id,
    pickerData,
    requiredAccess,
    restrictedItemIds,
    updateSelectedItem,
  ]);

  const handleClick = useCallback(() => {
    const itemIds: Array<number> = Object.keys(selection).map((id) => Number(id));

    if (allowMultiSelect) {
      onSelect(itemIds, packageStatus);
    } else {
      itemIds[0] && onSelect([itemIds[0]], packageStatus);
    }

    dispatch(clearItemPicker());
  }, [allowMultiSelect, dispatch, onSelect, packageStatus, selection]);

  const atTemplateProject = isTemplateDependency && parent.itemType === ItemType.PROJECTS;
  const disableMoveUpTreeAtCollection =
    disableMoveUpTreeAtPackageCollection && isWorkspaceRoot(parent) && !!packageStatus;
  const disableMoveUpTreeAParentContainerItem = parent.id === disableMoveUpTreeAtItemId && !isWorkspaceRoot(parent);
  const disableMoveUpTreeButton =
    atWorkspaceRoot || disableMoveUpTreeAtCollection || atTemplateProject || disableMoveUpTreeAParentContainerItem;

  return (
    <LpModal
      className="lp-item-picker"
      onClose={handlePickerClose}
      size="tiny"
      header={`${numberItemsSelected} Items Selected`}
      content={
        <div className="lp-item-picker__content-body">
          <span className="lp-item-picker__content-controls">
            <Button
              icon
              disabled={disableMoveUpTreeButton}
              className="item-picker-modal__content-controls-move-up-tree"
              compact
              content={<LpIcon icon={arrowAltUpSolid} size="1x" />}
              onClick={moveUpTree}
              size="tiny"
            />

            <LpSearchInputControlled
              className={'item-picker-modal__content-controls-search'}
              isDirty={isDirty}
              initialValue={inputValue}
              onChange={onChange}
              onClear={clearInput}
            />

            <IncludeDoneButton
              className="item-picker-modal__content-controls-include-done"
              handleClick={handleIncludeDoneItems}
              isChecked={includeDone}
            />
          </span>
          <div className="lp-item-picker__content-table">
            <ItemPickerParent
              parentId={parent?.id}
              atWorkspaceRoot={atWorkspaceRoot}
              packageStatus={packageStatus}
              updatePackageStatus={setPackageStatus}
              enableParentContainerSelection={enableParentSelection}
              onParentContainerSelection={updateSelectedItem}
            />
            <span className="lp-item-picker__content-table-items">
              {atWorkspaceRoot ? pkgStatusButtons : itemTargetButtons}
            </span>
          </div>
        </div>
      }
      actions={
        <>
          <Button onClick={handlePickerClose}> Cancel </Button>
          <Button disabled={isEmpty(selection)} onClick={handleClick} primary>
            {onSelectText}
          </Button>
        </>
      }
    />
  );
};

export default ItemPickerModal;
