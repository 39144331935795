export enum ClientSideColumnSort {
  Boolean = 'Boolean',
  DateRange = 'DATE_RANGE',
  Number = 'NUMBER',
  Range = 'RANGE',
  String = 'STRING',
}

export enum ExcelPreviewMessageType {
  Error = 'error',
  Warning = 'warning',
}

export enum GroupMemberBulkAddEditColumnKey {
  Id = 'id',
  Username = 'username',
  FirstName = 'firstName',
  LastName = 'lastName',
  AvatarColor = 'avatarColor',
}

export enum DataGridViewType {
  PORTFOLIO = 'Portfolio',
  PACKAGE = 'Package',
  PROJECT = 'Project',
  WIDGET = 'Widget',
}

export enum AddEditGridColumnKey {
  BillingRateSheet = 'billingRateSheet',
  Color = 'color',
  CostCodeId = 'costCodeId',
  Description = 'description',
  Done = 'done',
  DoneDate = 'doneDate',
  FolderStatus = 'folderStatus',
  HighEffortHours = 'highEffortHours',
  Id = 'id',
  IterationId = 'iterationId',
  LowEffortHours = 'lowEffortHours',
  Name = 'name',
  OrganizationUserId = 'organizationUserId',
  PackageStatus = 'packageStatus',
  PayRateSheet = 'payRateSheet',
  ScheduleDirective = 'scheduleDirective',
  TargetFinish = 'targetFinish',
  TargetFinishType = 'targetFinishType',
  TargetStart = 'targetStart',
  TaskStatusId = 'taskStatusId',
  TrackingOnly = 'trackingOnly',
  WorkLimit = 'workLimit',
  WorkType = 'workType',
}
