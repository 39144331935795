import classNames from 'classnames';
import { CSSProperties, Dispatch, memo, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { areEqual } from 'react-window';

import { ControlledItemLinkRenderer } from 'containers/shared/item_link';
import { SchedulingLabelsRenderer } from 'containers/shared/notice_icons/scheduling_labels/guest';
import { TaskStatusIcon } from 'containers/shared/task_icon_maker';
import { PackageStatus } from 'daos/enums';
import { WorkloadItem } from 'daos/model_types';
import { EllipsisActionDropdown } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown';
import { useEllipsisActionClickLocationContext } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/use_ellipsis_action_click_location';
import {
  EllipsisActionViewLocation,
  SetItemDeletionParams,
} from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import { ItemAncestryBreadcrumbRenderer } from 'features/common/item_ancestry_breadcrumb';
import WorkloadNub from 'features/common/workload/workload_table/workload_nub';
import { useClickLocationForContextMenu } from 'hooks/use_click_location_for_context_menu';
import { formatAsHours } from 'lib/display_helpers/format_as_hours';
import { convertSecondsToHours, roundedPercentage } from 'lib/helpers';
import { frontend } from 'lib/urls';
import { getItemForId, getPackageStatusForItemId } from 'redux/entities/selectors/item';
import {
  getWorkloadById,
  getWorkloadGroupItemsIndexedByItemIdForWorkloadIdAndOrgUserId,
  getWorkloadGroupsIndexedByGroupIdForWorkloadId,
} from 'redux/entities/selectors/workload';

interface NameCellProps {
  style: CSSProperties;
  fetchWorkload: () => void;
  workloadItem: WorkloadItem;
  setMoveItemId: Dispatch<SetStateAction<number | undefined>>;
  setDeletionData: Dispatch<SetStateAction<SetItemDeletionParams | undefined>>;
}

export const NameCell = memo(
  ({ style, fetchWorkload, workloadItem, setMoveItemId, setDeletionData }: NameCellProps) => {
    const locationPathName = useLocation().pathname;
    const item = useSelector((state) => getItemForId(state, workloadItem.id));
    const packageStatus = useSelector((state) => getPackageStatusForItemId(state, workloadItem.id));
    const guestRoute = !!useRouteMatch(frontend.dashboardGuestWidget.pattern)?.isExact;

    const { clickLocation, setClickLocation } = useClickLocationForContextMenu();
    const { handleShowContextMenu, handleHideContextMenu, triggerRef } = useEllipsisActionClickLocationContext(
      clickLocation,
      setClickLocation
    );

    const showThreeDotMenu = !guestRoute && item;

    return (
      <div
        style={style}
        className={classNames('workload-view__section-row-table-cell-name', {
          'workload-view__section-row-table-cell-name--no-three-dot': !showThreeDotMenu,
        })}
        onContextMenu={handleShowContextMenu}
      >
        <TaskStatusIcon
          className="workload-view__task-status-icon"
          name={workloadItem.taskStatus.name}
          color={`#${workloadItem.taskStatus.color}`}
          schedulingType={workloadItem.taskStatus.schedulingType}
        />

        <span className="workload-view__section-row-table-cell-name-text">
          <ControlledItemLinkRenderer
            disabled={guestRoute}
            className="workload-view__section-row-table-cell-name-text-link"
            itemName={workloadItem.itemName}
            to={`${locationPathName}#panelId=${workloadItem.item?.id}`}
          />
          <ItemAncestryBreadcrumbRenderer
            ancestryBreadcrumbs={workloadItem.ancestryBreadcrumbs}
            disabled={guestRoute}
          />
        </span>
        <SchedulingLabelsRenderer
          showSchedulingLimitsLabel={workloadItem.schedulingLabels.showSchedulingLimitLabel}
          showDependencyLabel={workloadItem.schedulingLabels.showDependencyLabel}
          showRiskLabel={workloadItem.schedulingLabels.showRiskLabel}
          asapStatus={workloadItem.schedulingLabels.asapStatus}
        />

        {showThreeDotMenu && (
          <EllipsisActionDropdown
            afterDelete={fetchWorkload}
            clickLocation={clickLocation}
            handleHideContextMenu={handleHideContextMenu}
            isTemplateGrid={packageStatus === PackageStatus.TEMPLATE}
            item={item}
            setDeletionData={setDeletionData}
            setMoveItemId={setMoveItemId}
            triggerRef={triggerRef}
            viewLocation={EllipsisActionViewLocation.WorkloadTaskRow}
          />
        )}
      </div>
    );
  },
  areEqual
);

interface NubCellProps {
  style: CSSProperties;
  workloadId: number;
  workloadGroupId: number;
  workloadItemId: number;
  nubIndex: number;
}
export const NubCell = memo(({ style, workloadId, workloadGroupId, workloadItemId, nubIndex }: NubCellProps) => {
  const workload = useSelector((state) => getWorkloadById(state, workloadId));
  const workloadGroups = useSelector((state) => getWorkloadGroupsIndexedByGroupIdForWorkloadId(state, workloadId));
  const workloadGroup = workloadGroups?.[workloadGroupId];
  const workloadGroupItems = useSelector((state) =>
    getWorkloadGroupItemsIndexedByItemIdForWorkloadIdAndOrgUserId(state, workloadId, workloadGroupId)
  );
  const workloadItem = workloadGroupItems[workloadItemId];

  const date = workload?.dates[nubIndex];

  if (!workloadItem || !date || !workloadGroup) {
    return null;
  }
  const { dailyAvailabilitySeconds, timeOffByDate } = workloadGroup;
  const { dailyWorkSeconds, lateByDate } = workloadItem;

  const lastDateIndex = workload.dates.length - 1;

  return (
    <div style={style} className="workload-view__section-row-nub">
      <WorkloadNub
        dailyWorkSeconds={dailyWorkSeconds[date]}
        date={date}
        nubColor={workloadItem.taskStatus.color}
        isParentTaskLate={workloadItem.itemLate}
        late={lateByDate[date] !== undefined}
        username={workloadGroup.username}
        taskName={workloadItem.itemName}
        timeOff={timeOffByDate[date] !== undefined}
        unavailable={(dailyAvailabilitySeconds[date] ?? 0) === 0}
        workScheduledBeyondRange={lastDateIndex === nubIndex}
      />
    </div>
  );
}, areEqual);

interface RemainingCellProps {
  style: CSSProperties;
  workloadItemId: number;
  workloadId: number;
  workloadGroupId: number;
}

export const RemainingCell = ({ style, workloadItemId, workloadId, workloadGroupId }: RemainingCellProps) => {
  const workloadGroupItems = useSelector((state) =>
    getWorkloadGroupItemsIndexedByItemIdForWorkloadIdAndOrgUserId(state, workloadId, workloadGroupId)
  );
  const workloadItem = workloadGroupItems[workloadItemId];

  if (!workloadItem) {
    return null;
  }

  return (
    <div style={style} className="workload-view__section-row-table-cell-remaining">
      <span>{formatAsHours(convertSecondsToHours(workloadItem.effortSeconds))}</span>
    </div>
  );
};

interface AvailabilityCellProps {
  style: CSSProperties;
  workloadGroupId: number;
  workloadItemId: number;
  workloadId: number;
}

export const AvailabilityCell = ({ style, workloadGroupId, workloadItemId, workloadId }: AvailabilityCellProps) => {
  const workloadGroups = useSelector((state) => getWorkloadGroupsIndexedByGroupIdForWorkloadId(state, workloadId));
  const workloadGroup = workloadGroups?.[workloadGroupId];
  const workloadGroupItems = useSelector((state) =>
    getWorkloadGroupItemsIndexedByItemIdForWorkloadIdAndOrgUserId(state, workloadId, workloadGroupId)
  );
  const workloadItem = workloadGroupItems[workloadItemId];

  if (!workloadItem) {
    return null;
  }

  return (
    <div style={style} className="workload-view__section-row-table-cell-availability">
      <span>{`${
        workloadGroup?.availabilitySeconds
          ? roundedPercentage(workloadItem.effortSeconds, workloadGroup.availabilitySeconds)
          : 100
      }%`}</span>
    </div>
  );
};
