import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, CheckboxProps, DropdownProps } from 'semantic-ui-react';

import { ScheduleDirective, PackageStatus, WorkType, RateSheetType } from 'daos/enums';
import { FolderStatus, TargetFinishType } from 'daos/item_enums';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { useCloseEditorOnScrollListener } from 'features/common/data_grid/add_edit_grid/cell_editors/use_scroll_listener';
import { AddEditGridColumnKey } from 'features/common/data_grid/enums';
import { EditorAddEditGridProps } from 'features/common/data_grid/types';
import ColorPickerDropdown from 'features/common/inputs/dropdowns/color_picker_dropdown';
import CostCodeDropdown from 'features/common/inputs/dropdowns/cost_code_dropdown';
import FolderStatusDropdown from 'features/common/inputs/dropdowns/folder_status_dropdown';
import PackageStatusDropdown from 'features/common/inputs/dropdowns/package_status_dropdown';
import { RateSheetDropdown } from 'features/common/inputs/dropdowns/rate_sheet_dropdown';
import {
  IterationPriorityDropdown,
  ScheduleDirectiveDropdown,
} from 'features/common/inputs/dropdowns/schedule_directive_dropdown';
import TargetFinishTypeDropdown from 'features/common/inputs/dropdowns/target_finish_type_dropdown';
import TaskStatusDropdown from 'features/common/inputs/dropdowns/task_status_dropdown';
import UserDropdown from 'features/common/inputs/dropdowns/user_dropdown';
import WorkTypeDropdown from 'features/common/inputs/dropdowns/work_type_dropdown';
import { LpDatePickerWithWorkdayTimeType } from 'features/common/inputs/lp_date_picker/with_workday_time_type';
import { clockSolid, LpIcon } from 'features/common/lp_icon';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import { WorkdayTimeType } from 'lib/workday_time_type';
import { getIterationsForCurrentWorkspace } from 'redux/entities/selectors/iterations';
import { getHasLoggedTimeForAssignmentId } from 'redux/entities/selectors/timesheet_entry';
import {
  getOrganizationUserForId,
  getNonGuestOrganizationUsersForCurrentWorkspaceSortedByUsername,
} from 'redux/entities/selectors/user';
import { slate700 } from 'style/variables';

export const CheckboxEditor = ({ column, row, onRowChange }: EditorAddEditGridProps) => {
  const columnKey = column.key as AddEditGridColumnKey.Done | AddEditGridColumnKey.TrackingOnly;
  const checked = !!row[columnKey];

  const handleChange = (_: SyntheticEvent, { checked }: CheckboxProps) => {
    onRowChange({ ...row, [columnKey]: !!checked });
  };

  return <Checkbox checked={checked} onChange={handleChange} />;
};

export const ColorDropdownEditor = (props: EditorAddEditGridProps) => {
  useCloseEditorOnScrollListener(props.onClose);

  const handleChange = ({ color }: { color: string }) => {
    props.onRowChange({ ...props.row, color }, true);
  };

  const handleEditorDropdownClose = () => props.onClose();

  return (
    <ColorPickerDropdown
      hex={props.row.color as string}
      onChange={handleChange}
      isOpen
      useOnGrid
      onClose={handleEditorDropdownClose}
    />
  );
};

export const CostCodeDropdownEditor = (props: EditorAddEditGridProps) => {
  useCloseEditorOnScrollListener(props.onClose);

  const handleChange = (costCodeId: number | undefined) => {
    props.onRowChange({ ...props.row, costCodeId }, true);
  };

  const handleEditorDropdownClose = () => props.onClose();

  const costCodeId = props.row.costCodeId;

  return (
    <CostCodeDropdown
      currentCostCodeId={costCodeId}
      onChange={handleChange}
      useOnGrid
      open
      onClose={handleEditorDropdownClose}
    />
  );
};

export const DateInputEditor = (props: EditorAddEditGridProps) => {
  const columnKey = props.column.key as
    | AddEditGridColumnKey.TargetStart
    | AddEditGridColumnKey.TargetFinish
    | AddEditGridColumnKey.DoneDate;

  const datePickerWorkdayTimeTypeForColumnKey = {
    [AddEditGridColumnKey.TargetStart]: WorkdayTimeType.WorkDayStart,
    [AddEditGridColumnKey.TargetFinish]: WorkdayTimeType.WorkDayFinish,
    [AddEditGridColumnKey.DoneDate]: WorkdayTimeType.WorkDayFinish,
  };

  const allowEmptyDate =
    columnKey === AddEditGridColumnKey.TargetStart || columnKey === AddEditGridColumnKey.TargetFinish;

  return (
    <LpDatePickerWithWorkdayTimeType
      open
      allowEmpty={allowEmptyDate}
      workdayTimeType={datePickerWorkdayTimeTypeForColumnKey[columnKey]}
      value={props.row[columnKey]}
      onClose={props.onClose}
      onChange={(date) => props.onRowChange({ ...props.row, [columnKey]: date }, true)}
    />
  );
};

export const FolderStatusDropdownEditor = (props: EditorAddEditGridProps) => {
  useCloseEditorOnScrollListener(props.onClose);

  const handleChange = ({ value }: { value: FolderStatus }) => {
    props.onRowChange({ ...props.row, folderStatus: value }, true);
  };

  const handleEditorDropdownClose = () => props.onClose();

  return (
    <FolderStatusDropdown
      onChange={handleChange}
      folderStatus={props.row.folderStatus as FolderStatus}
      isOpen={true}
      useOnGrid={true}
      onClose={handleEditorDropdownClose}
    />
  );
};

export const FirstAssigneeEditor = ({ column, onClose, onRowChange, row }: EditorAddEditGridProps) => {
  useCloseEditorOnScrollListener(onClose);

  const orgUsers = useSelector(getNonGuestOrganizationUsersForCurrentWorkspaceSortedByUsername);
  const columnKey = column.key as AddEditGridColumnKey.OrganizationUserId;
  const rowValue = row[columnKey];
  const handleChange = (_: SyntheticEvent, { value }: { value: number }) => {
    onRowChange({ ...row, [columnKey]: value }, true);
  };

  const handleEditorDropdownClose = () => onClose();

  return (
    <UserDropdown
      clearable
      isOpen
      onChange={handleChange}
      orgUsers={orgUsers}
      placeholder=""
      selectedOrgUserId={rowValue}
      usePortal
      onClose={handleEditorDropdownClose}
    />
  );
};

export const OrganizationUserDropdownEditor = (props: EditorAddEditGridProps) => {
  useCloseEditorOnScrollListener(props.onClose);

  const hasLoggedTime = useSelector((state) => getHasLoggedTimeForAssignmentId(state, props.row.id));
  const orgUsers = useSelector(getNonGuestOrganizationUsersForCurrentWorkspaceSortedByUsername);
  const orgUser = useSelector((state) =>
    props.row.organizationUserId ? getOrganizationUserForId(state, props.row.organizationUserId) : undefined
  );

  const handleChange = (_: SyntheticEvent, { value }: { value: number }) => {
    props.onRowChange({ ...props.row, organizationUserId: value }, true);
  };

  const handleEditorDropdownClose = () => props.onClose();

  if (hasLoggedTime) {
    const username = orgUser?.username ?? '';
    return (
      <span>
        {username} <LpIcon icon={clockSolid} color={slate700} />
      </span>
    );
  }

  return (
    <UserDropdown
      clearable
      isOpen
      onChange={handleChange}
      orgUsers={orgUsers}
      placeholder=""
      selectedOrgUserId={props.row.organizationUserId}
      usePortal
      onClose={handleEditorDropdownClose}
    />
  );
};

export const PackageStatusDropdownEditor = (props: EditorAddEditGridProps) => {
  useCloseEditorOnScrollListener(props.onClose);

  const handleChange = (_: SyntheticEvent, { value }: { value: PackageStatus }) => {
    props.onRowChange({ ...props.row, packageStatus: value }, true);
  };

  const handleEditorDropdownClose = () => props.onClose();

  const packageStatus = props.row.packageStatus;
  if (packageStatus === PackageStatus.TEMPLATE) {
    return <span>{packageStatusDisplayNameByPackageStatus[packageStatus] ?? ''}</span>;
  }

  return (
    <PackageStatusDropdown
      disabled={false}
      fluid={false}
      onChange={handleChange}
      packageStatus={props.row.packageStatus as PackageStatus}
      useOnGrid={true}
      open
      onClose={handleEditorDropdownClose}
    />
  );
};

const IterationPriorityDropdownEditor = ({ row, onClose, onRowChange }: EditorAddEditGridProps) => {
  const closeDropdown = () => onClose();
  const rowScheduleDirective = row.scheduleDirective ?? ScheduleDirective.NORMAL;
  const selectedIterationId = row.iterationId ?? null;
  const iterations = useSelector(getIterationsForCurrentWorkspace);

  const handleChange = ({
    scheduleDirective,
    iterationId,
  }: {
    scheduleDirective: ScheduleDirective;
    iterationId: number | null;
  }) => {
    onRowChange(
      {
        ...row,
        [AddEditGridColumnKey.ScheduleDirective]: scheduleDirective,
        [AddEditGridColumnKey.IterationId]: iterationId,
      },
      true
    );
  };

  return (
    <IterationPriorityDropdown
      isOpen
      usePortal
      iterations={iterations}
      onChange={handleChange}
      onClose={closeDropdown}
      scheduleDirective={rowScheduleDirective}
      selectedIterationId={selectedIterationId}
    />
  );
};

export const PriorityRushDropdownEditor = (props: EditorAddEditGridProps) => {
  useCloseEditorOnScrollListener(props.onClose);
  const hasIterationFeature = useHasFeature(FeatureFlag.iterationBucket);

  if (hasIterationFeature) {
    return <IterationPriorityDropdownEditor {...props} />;
  }

  const { column, row, onClose, onRowChange } = props;
  const columnKey = column.key as AddEditGridColumnKey.ScheduleDirective;
  const handleChange = (_: SyntheticEvent, { value }: DropdownProps) => {
    onRowChange({ ...row, [columnKey]: value as ScheduleDirective, [AddEditGridColumnKey.IterationId]: null }, true);
  };

  const handleEditorDropdownClose = () => onClose();

  return (
    <ScheduleDirectiveDropdown
      onChange={handleChange}
      scheduleDirective={row[columnKey] ?? ScheduleDirective.NORMAL}
      isOpen
      usePortal
      onClose={handleEditorDropdownClose}
    />
  );
};

export const RateSheetDropdownEditor = ({ column, onClose, onRowChange, row }: EditorAddEditGridProps) => {
  useCloseEditorOnScrollListener(onClose);
  const columnKey = column.key as AddEditGridColumnKey.PayRateSheet | AddEditGridColumnKey.BillingRateSheet;
  const itemId = row.id;
  const rowValue = row[columnKey];

  const rateSheetType =
    columnKey === AddEditGridColumnKey.PayRateSheet ? RateSheetType.PayRate : RateSheetType.BillingRate;

  const handleChange = (value: string | null) => {
    onRowChange({ ...row, [columnKey]: value }, true);
  };

  const handleEditorDropdownClose = () => onClose();

  return (
    <RateSheetDropdown
      className="rate-sheet-item-panel__dropdown"
      itemId={itemId}
      onChange={handleChange}
      rateSheetId={rowValue}
      useOnGrid
      isOpen
      onClose={handleEditorDropdownClose}
      rateSheetType={rateSheetType}
    />
  );
};

export const TargetFinishTypeDropdownEditor = ({ column, onClose, onRowChange, row }: EditorAddEditGridProps) => {
  useCloseEditorOnScrollListener(onClose);

  const columnKey = column.key as AddEditGridColumnKey.TargetFinishType;
  const rowValue = row[columnKey] ?? TargetFinishType.KeepScheduling;
  const handleChange = (targetFinishType: TargetFinishType) => {
    onRowChange({ ...row, [columnKey]: targetFinishType }, true);
  };

  const handleEditorDropdownClose = () => onClose();

  return (
    <TargetFinishTypeDropdown
      disabled={false}
      onChange={handleChange}
      targetFinishType={rowValue}
      isOpen
      useOnGrid
      onClose={handleEditorDropdownClose}
    />
  );
};

export const TaskStatusDropdownEditor = ({ column, onClose, onRowChange, row }: EditorAddEditGridProps) => {
  useCloseEditorOnScrollListener(onClose);

  const columnKey = column.key as AddEditGridColumnKey.TaskStatusId;
  const rowValue = row[columnKey];
  const handleChange = (_: SyntheticEvent, taskStatusId: number) => {
    onRowChange({ ...row, [columnKey]: taskStatusId }, true);
  };

  return <TaskStatusDropdown onClose={onClose} onChange={handleChange} taskStatusId={rowValue} useOnGrid open />;
};

export const WorkTypeDropdownEditor = ({ column, row, onClose, onRowChange }: EditorAddEditGridProps) => {
  useCloseEditorOnScrollListener(onClose);

  const columnKey = column.key as AddEditGridColumnKey.WorkType;
  const handleChange = (_: SyntheticEvent, { value }: { value: WorkType }) => {
    onRowChange({ ...row, [columnKey]: value }, true);
  };

  const handleEditorDropdownClose = () => onClose();

  return (
    <WorkTypeDropdown
      isOpen
      useOnGrid
      onClose={handleEditorDropdownClose}
      onChange={handleChange}
      workType={row[columnKey] ?? WorkType.IN_ORDER}
    />
  );
};
