import classNames from 'classnames';
import { ReactNode } from 'react';
import { Table } from 'semantic-ui-react';

export const JiraProjectTable = ({
  children,
  title,
  optionText = false,
  actionButton,
  isEmpty,
}: {
  children: ReactNode;
  title?: string;
  optionText?: boolean;
  actionButton?: ReactNode;
  isEmpty?: boolean;
}) => (
  <section>
    <div className="jira-project-modal__content-info-helper-container">
      <div className="jira-project-modal__content-info-helper-text">
        {title} {optionText && <span className="jira-project-modal__content-info-helper-text-italic">(Optional)</span>}
      </div>
    </div>
    <div className="jira-project-modal__content-table-action-button">{actionButton}</div>
    <Table
      celled
      definition
      compact
      className={classNames(
        'custom-field-table',
        'jira-project-modal__content-table',
        isEmpty && 'jira-project-modal__content-table--empty'
      )}
    >
      <Table.Body>{children}</Table.Body>
    </Table>
  </section>
);
