import { isString, noop } from 'lodash';
import { v4 as createUUID } from 'uuid';

import { Model } from 'daos/model_types';
import {
  ApiBody,
  ApiRequest,
  API_REQUEST,
  AwaitFinishAction,
  AWAIT_REQUEST_FINISH,
  BodyObject,
  ErrorPayload,
  FinishAction,
  HttpMethod,
  PayloadActions,
  SuccessPayload,
} from 'lib/api/types';
import { ReadonlyRecord } from 'lib/readonly_record';
import { EntityState } from 'redux/entities/types';
import { deleteEntityFinished, deleteBulkEntityFinished } from 'redux/sagas/api_saga/delete_actions';

const isBodyObject = (obj: any): obj is BodyObject =>
  obj && obj.type && !(obj instanceof FormData) && typeof obj !== 'string';

const isBodyObjectArray = (body: ApiBody): body is Array<BodyObject> => {
  if (Array.isArray(body)) {
    return body.every(isBodyObject);
  }
  return false;
};

interface MetaOptions {
  normalizeIncludedEntitiesOnly?: boolean;
  skipReduce?: boolean;
}

interface ApiOptions {
  body?: ApiBody;
  headers?: {
    'Content-Type'?: string;
    Accept?: string;
  };
  meta?: MetaOptions;
  method: HttpMethod;
}

const reduceTypes = (
  acc: ReadonlyRecord<string, any>,
  current: ReadonlyRecord<string, any>
): ReadonlyRecord<string, any> => {
  return {
    ...acc,
    [current.type]: {
      ...acc[current.type],
      [current.id]: current,
    },
  };
};

const jsonApiData = (
  type: string,
  attributes: Record<string, any> = {},
  relationships: Record<string, any> = {},
  id: string | number | undefined
): string =>
  JSON.stringify({
    data: { id, type, ...attributes, ...relationships },
  });

const createPayloadActionsForModelType = (modelType: string, normalizeIncludedEntitiesOnly = false): PayloadActions => {
  const successType = `${modelType}_SUCCESS`;
  const failureType = `${modelType}_FAILURE`;
  const beginPayloadAction = { type: modelType };
  const failurePayloadAction = { type: failureType };
  const successActionHandler = {
    payload: async (res: Response) => {
      const contentType = res.headers.get('Content-Type');
      if (contentType && ~contentType.indexOf('json')) {
        return res.json().then((json) => ({
          data: json.data,
          entities: normalizeIncludedEntitiesOnly ? normalize({ data: {}, included: json.included }) : normalize(json),
          result: getDataResultIds(json.data),
          pageData: {
            continuationToken: json.continuationToken,
            recordLimit: json.recordLimit,
            recordCount: json.recordCount,
          },
        }));
      }
    },
    type: successType,
  };

  return [beginPayloadAction, successActionHandler, failurePayloadAction];
};

export const normalize = ({
  data,
  included,
}: {
  included?: Partial<EntityState>;
  data: ReadonlyRecord<string, any> | ReadonlyArray<any> | null;
}): Partial<EntityState> => {
  let results = { ...included } ?? {};

  if (!data) {
    return results;
  }

  if (Array.isArray(data)) {
    data.forEach((datum) => (results = reduceTypes(results, datum)));
  } else {
    results = reduceTypes(results, data);
  }
  return results;
};

export const getDataResultIds = (data: Model<string> | Array<Model<string>> | null) => {
  if (!data) {
    return [];
  }

  if (Array.isArray(data)) {
    return data.map((datum: Model<string>) => datum.id);
  }

  return [data.id];
};

export function awaitRequestFinish<T>(
  requestUuid: string,
  methods: {
    onSuccess?: (response: SuccessPayload<T>) => void;
    onError?: (errorResponse: ErrorPayload) => void;
    onFinish?: () => void;
  }
): AwaitFinishAction {
  const defaultNoOp = noop;
  return {
    payload: {
      requestUuid,
      methods: {
        onSuccess: methods.onSuccess ?? defaultNoOp,
        onError: methods.onError,
        onFinish: methods.onFinish ?? defaultNoOp,
      },
    },
    type: AWAIT_REQUEST_FINISH,
  };
}

export const request = (url: string, modelType: string, options: ApiOptions): ApiRequest => {
  const { headers = {}, meta = {} } = options;
  const uuid = createUUID();

  if (url.substring(0, 1) !== '/') {
    url = `/${url}`;
  }

  const { body, method, ...otherFetchOpts } = options;

  const payloadActions = createPayloadActionsForModelType(modelType, meta?.normalizeIncludedEntitiesOnly);

  let payloadBody: BodyInit | undefined;
  let finishAction: FinishAction;

  if (body && isBodyObjectArray(body)) {
    const bulkEntityIds: Array<number> = [];
    const entityType = body[0]?.type as keyof EntityState | undefined;

    payloadBody = JSON.stringify({
      data: body.map(({ attributes, relationships, id, type }: BodyObject) => {
        if (id) {
          bulkEntityIds.push(id);
        }
        const combinedProperties = { ...attributes, ...relationships };
        return { id, type, ...combinedProperties };
      }),
    });

    if (entityType && method === HttpMethod.DELETE) {
      finishAction = deleteBulkEntityFinished(entityType, bulkEntityIds);
    }
  } else if (isBodyObject(body)) {
    const { attributes, id, relationships, type: entityType } = body;
    payloadBody = jsonApiData(entityType, attributes, relationships, id);

    if (method === HttpMethod.DELETE && id) {
      finishAction = deleteEntityFinished(entityType as keyof EntityState, id);
    }
  }

  if (body instanceof FormData) {
    payloadBody = body;
  }

  if (isString(body)) {
    payloadBody = body;
  }

  if (!(body instanceof FormData)) {
    headers['Content-Type'] = headers['Content-Type'] || 'application/json';
  }

  const payload: ApiRequest['payload'] = {
    body: payloadBody,
    endpoint: url,
    finishAction,
    headers,
    method,
    payloadActions,
    uuid,
    ...otherFetchOpts,
  };

  return { type: API_REQUEST, payload, uuid, meta };
};
