import moment from 'moment-timezone';
import * as yup from 'yup';

import { JiraProjectModalFormFields, JiraProjectModalFormValues, JiraTabKey } from 'features/jira_project/modal/types';
import { ISO_DATE_FORMAT } from 'lib/localization';

const oauthCredentialsIdValidation = yup.number().min(1, 'Please select an email').required('Please select an email');
const cloudIdValidation = yup.string().trim().required('Please select a Jira instance');
const jiraProjectIdValidation = yup.string().trim().required('Please select a Jira project');
const jiraProjectIssueTypeIdsValidation = yup.array().min(1, 'Please select at least one issue type');
const jiraProjectStatusIdsValidation = yup.array().min(1, 'Please select at least one status');
const jiraProjectIssueDateFieldValidation = yup
  .string()
  .trim()
  .required('Please select a date field')
  .test('is-ISO', 'Invalid date format', (value) => {
    const date = moment(value);
    return date.isValid() && value === date.format(ISO_DATE_FORMAT);
  });
const jiraProjectLowEstimateFieldValidation = yup.number().required('Please select a low estimate field');
const jiraProjectHighEstimateFieldValidation = yup.number().required('Please select a high estimate field');
const costCodeIdValidation = (costCodeRequired?: boolean) =>
  yup
    .mixed()
    .nullable()
    .test('costCodeId', 'Cost Code is required for logging time.', (value, { parent }) => {
      const { syncJiraWorklog } = parent as JiraProjectModalFormValues;
      if (!costCodeRequired) {
        return true;
      }
      return !(costCodeRequired && syncJiraWorklog) || Boolean(value);
    });

export const validateActiveTab = (activeTab: JiraTabKey, costCodeRequired?: boolean) => {
  switch (activeTab) {
    case JiraTabKey.JiraInstance:
      return yup.object().shape({
        [JiraProjectModalFormFields.OauthCredentialsId]: oauthCredentialsIdValidation,
        [JiraProjectModalFormFields.CloudId]: cloudIdValidation,
      });
    case JiraTabKey.ProjectAndIssuesFilter:
      return yup.object().shape({
        [JiraProjectModalFormFields.JiraProjectId]: jiraProjectIdValidation,
        [JiraProjectModalFormFields.IssueTypeIds]: jiraProjectIssueTypeIdsValidation,
        [JiraProjectModalFormFields.IssueStatusIds]: jiraProjectStatusIdsValidation,
        [JiraProjectModalFormFields.IssueCreatedDate]: jiraProjectIssueDateFieldValidation,
      });
    case JiraTabKey.SyncSettings:
      return yup.object().shape({
        [JiraProjectModalFormFields.CostCodeId]: costCodeIdValidation(costCodeRequired),
      });
    case JiraTabKey.RemainingEstimates:
      return yup.object().shape({
        [JiraProjectModalFormFields.LowEstimate]: jiraProjectLowEstimateFieldValidation,
        [JiraProjectModalFormFields.HighEstimate]: jiraProjectHighEstimateFieldValidation,
      });
    default:
      return yup.object().shape({});
  }
};
