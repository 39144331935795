import { useFormikContext } from 'formik';
import { useState } from 'react';
import { Button } from 'semantic-ui-react';

import { WidgetDataErrorType, WidgetType } from 'daos/enums';
import { WidgetConfig } from 'daos/types';
import { WidgetConfigColumn } from 'daos/widget_column';
import { WidgetGridColumnSelectModal } from 'features/common/data_grid/column_selector_modals';
import { SelectionList } from 'features/common/selection_list/types';
import { mapSelectionListToColumns } from 'features/dashboards_v2/widget/common/columns';
import { WidgetDataErrorWarningLabel } from 'features/dashboards_v2/widget_data_error_warning';
import { WidgetSettingsV2Field } from 'features/dashboards_v2/widget_settings/field';
import { useWidgetColumns } from 'features/dashboards_v2/widget_settings/use_widget_columns';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';

export const WidgetColumnPicker = ({
  allGridColumns,
  defaultColumns,
  widgetDataError,
  widgetType,
}: {
  allGridColumns: ReadonlyArray<SelectionList>;
  defaultColumns: ReadonlyArray<WidgetConfigColumn>;
  widgetDataError?: WidgetDataErrorType;
  widgetType: WidgetType.MetricsTally | WidgetType.PropertiesSummary;
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const { getFieldProps, setFieldValue } = useFormikContext<WidgetConfig>();
  const columns = getFieldProps<ReadonlyArray<WidgetConfigColumn> | null>('columns').value ?? [];

  const {
    openSelectColumnModal,
    columnModalOpen,
    setColumnModalOpen,
    initialColumns,
    columnsSelected,
    setColumnsSelected,
  } = useWidgetColumns({
    defaultColumns,
    columns,
  });

  function updateSelectedOptions(updatedColumns: Array<SelectionList>) {
    setFieldValue('columns', mapSelectionListToColumns(updatedColumns, null, null));
    setIsDirty(true);
  }

  const widgetTypeDisplayName = widgetType === WidgetType.MetricsTally ? 'Metrics' : 'Properties';

  return (
    <>
      <WidgetSettingsV2Field name="Rows">
        <Button
          type="button"
          onClick={openSelectColumnModal}
          content={`Choose ${widgetTypeDisplayName} to Show${HORIZONTAL_ELLIPSIS}`}
        />
        {!isDirty && <WidgetDataErrorWarningLabel widgetDataErrorType={widgetDataError} />}
      </WidgetSettingsV2Field>
      {columnModalOpen && (
        <WidgetGridColumnSelectModal
          allGridColumns={allGridColumns}
          onClose={() => setColumnModalOpen(false)}
          initialGridColumns={initialColumns}
          selectedGridColumns={columnsSelected}
          setWidgetColumnsSelected={setColumnsSelected}
          updateSelectedOptions={updateSelectedOptions}
          availableTitle={`Available ${widgetTypeDisplayName}`}
          selectedTitle={`Selected ${widgetTypeDisplayName}`}
        />
      )}
    </>
  );
};
