export const compareStringsAscendingOrder = (a: string, b: string) => {
  if ((a && !b) || a > b) {
    return 1;
  } else if ((!a && b) || a < b) {
    return -1;
  }
  return 0;
};

interface Prioritizable {
  readonly priority: string;
}

export function compareByPriority(a: Prioritizable | null | undefined, b: Prioritizable | null | undefined) {
  const aPriority = a?.priority ?? '';
  const bPriority = b?.priority ?? '';

  // Since priorities are [0-9a-z], we can rely on fast comparisons instead of localeCompare
  if (aPriority < bPriority) {
    return -1;
  } else if (aPriority > bPriority) {
    return 1;
  } else {
    return 0;
  }
}
