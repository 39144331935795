import { useSelector } from 'react-redux';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import { SourceSystem } from 'daos/external_integration_enums';
import { getColumnSelectionList } from 'features/common/selection_list';
import { getNonSpecialActiveCustomFieldsForCurrentWorkspace } from 'redux/entities/selectors/custom_field';
import { getPropertiesWidgetColumns } from 'redux/entities/selectors/system_settings';

export const usePropertiesWidgetCustomFieldsAndCustomColumns = () => {
  const allCustomFields = useSelector(getNonSpecialActiveCustomFieldsForCurrentWorkspace).filter((field) => {
    if (field.sourceSystem === SourceSystem.JIRA && field.name.includes('Project Lead')) {
      return field;
    }
  });

  const allCustomColumnsExceptCustomField = useSelector(getPropertiesWidgetColumns).filter(
    (column) => column !== StandardColumns.CustomField
  );

  return getColumnSelectionList({
    columnOptions: allCustomColumnsExceptCustomField,
    customFields: allCustomFields,
  });
};
