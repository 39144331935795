import { createCachedSelector } from 're-reselect';

import { ItemMetricValueProps } from 'containers/shared/custom_column/types';
import { ItemMetrics } from 'daos/model_types';
import { convertSecondsToHours } from 'lib/helpers';
import {
  getOnHoldHours,
  getPercentComplete,
  getRemainingWorkExpected,
  getUncertainWork,
} from 'lib/helpers/item_metric_calculations';
import { createCacheByIdConfig } from 'redux/entities/selectors/shared';
import { RootState } from 'redux/root_reducer';

export enum ItemMetricHeader {
  AssignmentCount = 'assignmentCount',
  BenchmarkEstimate = 'benchmarkEstimate',
  ClippedEffort = 'clippedEffort',
  Logged = 'logged',
  OnHoldHours = 'onHoldHours',
  PercentComplete = 'percentComplete',
  PercentTasksComplete = 'percentTasksComplete',
  RemainingEstimate = 'remainingEstimate',
  TaskCount = 'taskCount',
  TotalWork = 'totalWork',
  UncertainWork = 'uncertainWork',
  UnusedEffortExpected = 'unusedEffortExpected',
  WorkLimit = 'workLimit',
  workLimitDelta = 'workLimitDelta',
}

export const getFilterAwareItemMetricsById = (state: RootState) => state.entities.filterAwareItemMetrics;

export const getItemMetricsById = (state: RootState) => state.entities.itemMetrics;
export const getItemMetricsForId = (state: RootState, itemId: number) => getItemMetricsById(state)[itemId];

export const getItemMetricValueProps = (itemMetric: ItemMetrics | undefined) => {
  const earliestDoneDate = itemMetric?.earliestDoneDate ?? '';
  const effectiveTargetStart = itemMetric?.effectiveTargetStart ?? '';
  const effectiveTargetFinish = itemMetric?.effectiveTargetFinish ?? '';
  const latestDoneDate = itemMetric?.latestDoneDate ?? '';
  const rollupEarliestActiveTargetFinish = itemMetric?.rollupEarliestActiveTargetFinish ?? '';
  const rollupEarliestTargetStart = itemMetric?.rollupEarliestTargetStart ?? '';
  const rollupLatestTargetFinish = itemMetric?.rollupLatestTargetFinish ?? '';

  const highRemainingWork = itemMetric?.highRemainingWork ?? 0;
  const lowRemainingWork = itemMetric?.lowRemainingWork ?? 0;
  const highRemainingWorkHours = convertSecondsToHours(highRemainingWork);
  const lowRemainingWorkHours = convertSecondsToHours(lowRemainingWork);

  const loggedWork = itemMetric?.loggedWork ?? 0;
  const unusedEffort = itemMetric?.unusedEffort ?? 0;

  const onHoldHours = convertSecondsToHours(getOnHoldHours(itemMetric) ?? 0);

  const highOnHoldWork = itemMetric?.highOnHoldWork ?? 0;
  const lowOnHoldWork = itemMetric?.lowOnHoldWork ?? 0;
  const highOnHoldHours = convertSecondsToHours(highOnHoldWork);
  const lowOnHoldHours = convertSecondsToHours(lowOnHoldWork);
  const projectScheduledCount = itemMetric?.scheduledProjectCount ?? 0;
  const projectDoneCount = itemMetric?.doneProjectCount ?? 0;
  const projectOnHoldCount = itemMetric?.onHoldProjectCount ?? 0;

  const taskActiveCount = itemMetric?.activeTaskCount ?? 0;
  const taskDoneCount = itemMetric?.doneTaskCount ?? 0;
  const taskOnHoldCount = itemMetric?.onHoldTaskCount ?? 0;

  const assignmentActiveCount = itemMetric?.activeAssignmentCount ?? 0;
  const assignmentDoneCount = itemMetric?.doneAssignmentCount ?? 0;

  const workLimitDelta = itemMetric?.workLimitDelta ?? 0;
  const workLimitAlert = !!itemMetric?.workLimitAlert;

  const remainingWorkExpected = itemMetric ? getRemainingWorkExpected(itemMetric) : 0;
  const totalWorkExpected = remainingWorkExpected + loggedWork;

  const lowTotalWork = lowRemainingWork + loggedWork;
  const highTotalWork = highRemainingWork + loggedWork;
  const lowTotalWorkHours = convertSecondsToHours(lowTotalWork);
  const highTotalWorkHours = convertSecondsToHours(highTotalWork);

  const projectTotalCount = projectScheduledCount + projectDoneCount + projectOnHoldCount;
  const taskTotalCount = taskActiveCount + taskDoneCount + taskOnHoldCount;
  const assignmentTotalCount = assignmentActiveCount + assignmentDoneCount;

  const uncertainWork = getUncertainWork(itemMetric);

  const props: ItemMetricValueProps = {
    assignmentActiveCount,
    assignmentDoneCount,
    assignmentTotalCount,
    earliestDoneDate,
    effectiveTargetFinish,
    effectiveTargetStart,
    highRemainingWorkHours,
    highTotalWorkHours,
    latestDoneDate,
    loggedWorkHours: convertSecondsToHours(loggedWork),
    lowRemainingWorkHours,
    lowTotalWorkHours,
    percentComplete: getPercentComplete(loggedWork, totalWorkExpected + getOnHoldHours(itemMetric)),
    percentTasksComplete: getPercentComplete(taskDoneCount, taskTotalCount),
    onHoldHours,
    onHoldHoursRange: {
      lowOnHoldHours: lowOnHoldHours,
      highOnHoldHours: highOnHoldHours,
    },
    projectDoneCount,
    projectOnHoldCount,
    projectScheduledCount,
    projectTotalCount,
    remainingWorkExpectedHours: convertSecondsToHours(remainingWorkExpected),
    remainingWorkRange: {
      lowRemainingWorkHours,
      highRemainingWorkHours,
    },
    rollupEarliestActiveTargetFinish,
    rollupEarliestTargetStart,
    rollupLatestTargetFinish,
    taskActiveCount,
    taskDoneCount,
    taskOnHoldCount,
    taskTotalCount,
    totalWorkExpectedHours: convertSecondsToHours(totalWorkExpected),
    totalWorkRange: {
      lowTotalWorkHours,
      highTotalWorkHours,
    },
    uncertainWorkHours: convertSecondsToHours(uncertainWork),
    unusedEffortHours: convertSecondsToHours(unusedEffort),
    workLimitAlert,
    workLimitDeltaHours: convertSecondsToHours(workLimitDelta),
  };

  return props;
};

export const getFilterAwareItemMetricValuePropsForId = createCachedSelector(
  getFilterAwareItemMetricsById,
  getItemMetricsById,
  (_: RootState, itemId: number) => itemId,
  (filterAwareItemMetricsById, itemMetricsById, itemId) => {
    const itemMetric = filterAwareItemMetricsById[itemId] ?? itemMetricsById[itemId];
    return getItemMetricValueProps(itemMetric);
  }
)(createCacheByIdConfig());
