import { useFormikContext } from 'formik';
import { SyntheticEvent, useEffect } from 'react';

import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { JiraProjectModalPicklist } from 'features/jira_project/modal/sections/jira_project_modal_picklist';
import {
  FieldMappingValue,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  expectedFinishItem,
  jiraDueDateTargetFinishInitialValue,
  jiraFieldDateMapping,
  lpNameSystemMapping,
} from 'features/jira_project/modal/types';
import { getAvailableFieldValueOptions } from 'features/jira_project/modal/utils';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

export const ProjectIdPicklist = () => {
  const hasCustomFieldFlagEnabled = useHasFeature(FeatureFlag.jiraCustomFieldMapping);
  const { values, setFieldValue, validateField } = useFormikContext<JiraProjectModalFormValues>();
  const {
    setJiraProjectName,
    isEditMode,
    jiraProjects,
    setAddedLpFieldMappingRowItems,
    setAddedJiraFieldMappingRowItems,
    jiraProjectMappingFieldData,
  } = useJiraProjectModalContext();

  const picklistOptions = getAvailableFieldValueOptions(new Set([]), jiraProjects);
  const selectedJiraProjectId = values[JiraProjectModalFormFields.JiraProjectId];

  const resetAffectedFieldValues = () => {
    const dueDateOption = jiraProjectMappingFieldData.find((item) => item.id === 'duedate') ?? jiraFieldDateMapping;

    setAddedJiraFieldMappingRowItems(new Set([dueDateOption]));
    setFieldValue(JiraProjectModalFormFields.IssueTypeIds, []);
    setFieldValue(JiraProjectModalFormFields.IssueStatusIds, []);
    setFieldValue(
      JiraProjectModalFormFields.FieldMappings,
      hasCustomFieldFlagEnabled ? [jiraDueDateTargetFinishInitialValue] : []
    );
    setAddedJiraFieldMappingRowItems(new Set([dueDateOption]));
    setAddedLpFieldMappingRowItems(
      new Set(hasCustomFieldFlagEnabled ? [expectedFinishItem as FieldMappingValue] : lpNameSystemMapping)
    );
  };

  const handleChangeValue = (_: SyntheticEvent, { value: picklistChoiceId }: { value: string | undefined }) => {
    if (!picklistChoiceId) {
      setJiraProjectName('');
      setFieldValue(JiraProjectModalFormFields.JiraProjectId, '');
    } else {
      const selectedJiraProjectName = jiraProjects.find((jiraProject) => jiraProject.id === picklistChoiceId)?.name;
      setJiraProjectName(selectedJiraProjectName ?? '');
      setFieldValue(JiraProjectModalFormFields.JiraProjectId, picklistChoiceId);
    }
    resetAffectedFieldValues();
  };

  useEffect(() => {
    if (selectedJiraProjectId) {
      validateField(JiraProjectModalFormFields.JiraProjectId);
    }
  }, [selectedJiraProjectId, validateField]);

  return (
    <JiraProjectModalPicklist
      placeholder="Select Jira Project"
      picklist={picklistOptions(undefined)}
      name={JiraProjectModalFormFields.JiraProjectId}
      onChange={handleChangeValue}
      renderIcon={false}
      disabled={isEditMode}
    />
  );
};
