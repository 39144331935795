import { ComponentType } from 'react';

import { CustomFieldType } from 'daos/enums';
import { TaskGroupHierarchy } from 'daos/external_integration_enums';

export interface JiraProject {
  readonly name: string;
  readonly id: string;
}

export enum JiraProjectModalFormFields {
  OauthCredentialsId = 'oauthCredentialsId',
  CloudId = 'cloudId',
  JiraProjectId = 'jiraProjectId',
  IssueTypeIds = 'issueTypeIds',
  IssueStatusIds = 'issueStatusIds',
  IssueCreatedDate = 'issueCreatedDate',
  LowEstimate = 'lowEstimate',
  HighEstimate = 'highEstimate',
  SyncJiraWorklog = 'syncJiraWorklog',
  SyncTimesheetWorklog = 'syncTimesheetWorklog',
  TaskGroupHierarchy = 'taskGroupHierarchy',
  RankIssues = 'rankIssues',
  CostCodeId = 'costCodeId',
  FieldMappings = 'fieldMappings',
}

enum JiraProjectModalFormFieldMappingItem {
  LpCustomFieldId = 'lpCustomFieldId',
  LpSystemField = 'lpSystemField',
  FieldType = 'fieldType',
  SourceFieldId = 'sourceFieldId',
  IsPull = 'isPull',
  IsPush = 'isPush',
}

export type AllowedCustomField =
  | CustomFieldType.DATE
  | CustomFieldType.NUMERIC
  | CustomFieldType.TEXT
  | CustomFieldType.PICKLIST
  | CustomFieldType.MULTI_PICKLIST
  | CustomFieldType.LINK;

export interface JiraProjectModalFormFieldMapping {
  [JiraProjectModalFormFieldMappingItem.FieldType]: AllowedCustomField;
  [JiraProjectModalFormFieldMappingItem.SourceFieldId]: string;
  [JiraProjectModalFormFieldMappingItem.LpCustomFieldId]: number | null;
  [JiraProjectModalFormFieldMappingItem.LpSystemField]: string | null;
  [JiraProjectModalFormFieldMappingItem.IsPull]: boolean;
  [JiraProjectModalFormFieldMappingItem.IsPush]: boolean;
}

export interface JiraProjectModalFormValues {
  [JiraProjectModalFormFields.OauthCredentialsId]: number;
  [JiraProjectModalFormFields.CloudId]: string;
  [JiraProjectModalFormFields.JiraProjectId]: string;
  [JiraProjectModalFormFields.IssueTypeIds]: Array<string>;
  [JiraProjectModalFormFields.IssueStatusIds]: Array<string>;
  [JiraProjectModalFormFields.IssueCreatedDate]: string;
  [JiraProjectModalFormFields.LowEstimate]: number;
  [JiraProjectModalFormFields.HighEstimate]: number;
  [JiraProjectModalFormFields.SyncJiraWorklog]: boolean;
  [JiraProjectModalFormFields.SyncTimesheetWorklog]: boolean;
  [JiraProjectModalFormFields.TaskGroupHierarchy]: TaskGroupHierarchy;
  [JiraProjectModalFormFields.RankIssues]: boolean;
  [JiraProjectModalFormFields.CostCodeId]: number | null;
  [JiraProjectModalFormFields.FieldMappings]: Array<JiraProjectModalFormFieldMapping>;
}
export interface JiraTab {
  component: ComponentType;
  name: TabNames;
  key: JiraTabKey;
}
export interface JiraProjectIssueCount {
  count: number;
}

export enum FieldMappingRowType {
  JiraToLp = 'jiraToLp',
  LpToJira = 'lpToJira',
}

export enum JiraTabKey {
  Welcome = 'welcome',
  JiraInstance = 'jira-instance-and-credentials',
  ProjectAndIssuesFilter = 'project-and-issues-filter',
  SyncSettings = 'sync-settings',
  FieldMapping = 'field-mapping',
  RemainingEstimates = 'remaining-estimates',
  Launch = 'launch',
}

export enum TabNames {
  Welcome = 'Welcome',
  JiraInstanceAndCredentials = 'Instance & Credentials',
  ProjectAndIssuesFilter = 'Project & Issues Filter',
  SyncSettings = 'Sync Settings',
  FieldMapping = 'Field Mapping',
  RemainingEstimates = 'Remaining Estimates',
  Launch = 'Launch',
}

export const jiraFieldDateMapping = {
  name: 'Due Date',
  type: CustomFieldType.DATE,
  id: 'duedate',
};

export interface FieldMappingValue {
  id: string;
  name: string;
  type: string;
  custom?: string;
}

export enum LpSystemName {
  TargetFinish = 'Target Finish',
  TargetStart = 'Target Start',
  ExpectedStart = 'Expected Start',
  ExpectedFinish = 'Expected Finish',
  LatestFinish = 'Latest Finish',
  LpId = 'LiquidPlanner ID',
  ItemUrl = 'Item Link',
}

export enum LpSystemId {
  TargetFinish = 'TARGET_FINISH',
  TargetStart = 'TARGET_START',
  ExpectedStart = 'EXPECTED_START',
  ExpectedFinish = 'EXPECTED_FINISH',
  LatestFinish = 'LATEST_FINISH',
  LpId = 'ID',
  ItemUrl = 'ITEM_URL',
}

export enum CalculatedLpSystemId {
  LpId = 'ID',
  ExpectedStart = 'EXPECTED_START',
  ExpectedFinish = 'EXPECTED_FINISH',
  LatestFinish = 'LATEST_FINISH',
  ItemUrl = 'ITEM_URL',
}

export const jiraDueDateTargetFinishInitialValue: JiraProjectModalFormFieldMapping = {
  sourceFieldId: 'duedate',
  lpCustomFieldId: null,
  lpSystemField: LpSystemId.TargetFinish,
  fieldType: CustomFieldType.DATE,
  isPull: true,
  isPush: false,
};

export interface JiraProjectModalPicklistData {
  id: string | number;
  name: string;
  disabled?: boolean;
}

export const lpNameSystemMapping: Array<FieldMappingValue> = [
  {
    id: LpSystemId.TargetFinish,
    name: LpSystemName.TargetFinish,
    type: CustomFieldType.DATE,
  },
  {
    id: LpSystemId.TargetStart,
    name: LpSystemName.TargetStart,
    type: CustomFieldType.DATE,
  },
  {
    id: LpSystemId.ExpectedStart,
    name: LpSystemName.ExpectedStart,
    type: CustomFieldType.DATE,
  },
  {
    id: LpSystemId.ExpectedFinish,
    name: LpSystemName.ExpectedFinish,
    type: CustomFieldType.DATE,
  },
  {
    id: LpSystemId.LatestFinish,
    name: LpSystemName.LatestFinish,
    type: CustomFieldType.DATE,
  },
  {
    id: LpSystemId.LpId,
    name: LpSystemName.LpId,
    type: CustomFieldType.NUMERIC,
  },
  {
    id: LpSystemId.ItemUrl,
    name: LpSystemName.ItemUrl,
    type: CustomFieldType.LINK,
  },
];

export const expectedFinishItem = Array.from(lpNameSystemMapping).find((item) => item.id === LpSystemId.ExpectedFinish);

export enum SyncErrorMessage {
  JiraProjectNotFound = 'TBD',
  InvalidResource = 'Authentication failed: You do not have access to the connected JIra Instance. Please check your permissions and try again.',
  DisconnectedUser = 'Authentication error: the authenticated user account has been disconnected. Click on the "Replace Authentication" button to reconnect with a different account.',
}

export enum TimesheetAuthStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Error = 'ERROR',
}

export interface TimesheetAuthResponse {
  result: TimesheetAuthStatus;
}

export enum WorklogOption {
  DO_NOT_PULL = 'Do not pull any Worklogs',
  PULL_FROM_JIRA = 'Pull from Jira',
  PULL_FROM_TEMPO_TIMESHEETS = 'Pull from Tempo Timesheets',
}

export enum SubFolderOrganizationName {
  FLAT_LIST = 'Flat List',
  EPIC_BASED_SUBFOLDERS = 'Epic-Based Sub-folders',
  HIERARCHICAL_SUBFOLDERS = 'Hierarchical Sub-folders',
}

export interface SubFolderOrganizationValues {
  id: TaskGroupHierarchy;
  name: SubFolderOrganizationName;
  description: string;
  subtitle: string;
  image: {};
}

export enum JiraCustomFieldType {
  TextField = 'com.atlassian.jira.plugin.system.customfieldtypes:textfield',
  Url = 'com.atlassian.jira.plugin.system.customfieldtypes:url',
}
