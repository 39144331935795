import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { TaskStatus } from 'daos/model_types';
import { TaskStatusDao } from 'daos/task_status';
import { createCustomFieldValuesPayload } from 'features/common/data_grid/add_edit_grid/custom_field_helpers';
import { getWorkLimitPayload } from 'features/common/data_grid/add_edit_grid/helpers/payload_helpers';
import { allRowsAreValid } from 'features/common/data_grid/add_edit_grid/modal_edit_grid/edit_item_grids/helpers';
import {
  useTaskEditColumnDefinitions,
  useTasksToEditGridRows,
} from 'features/common/data_grid/add_edit_grid/modal_edit_grid/hooks/use_task_edit_data_grid';
import LpEditGridModal from 'features/common/data_grid/add_edit_grid/modal_edit_grid/lp_edit_grid_modal';
import {
  MultiFieldValueModal,
  MultiFieldEditorProps,
} from 'features/common/data_grid/add_edit_grid/multi_field_value_modal';
import { AddEditGridRow } from 'features/common/data_grid/types';
import { getEditGridCustomFieldValuesByItemId, getFieldsById } from 'redux/entities/selectors/custom_field';
import { getItemsForIdsSortedByGlobalPriority } from 'redux/entities/selectors/item';

interface EditTaskGridModalProps {
  fetchTasks: () => void;
  isTemplateGrid: boolean;
  selectedTaskIds: ReadonlyArray<number>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditTaskGridModal = ({ fetchTasks, isTemplateGrid, selectedTaskIds, setOpen }: EditTaskGridModalProps) => {
  const tasks = useSelector((state) => getItemsForIdsSortedByGlobalPriority(state, selectedTaskIds));
  const customFieldsKeyedById = useSelector(getFieldsById);
  const fieldValuesByItemId = useSelector(getEditGridCustomFieldValuesByItemId);

  const [multiFieldEditorProps, setMultiFieldEditorProps] = useState<MultiFieldEditorProps | undefined>();
  const closeMultiEditModal = () => setMultiFieldEditorProps(undefined);

  const columns = useTaskEditColumnDefinitions(isTemplateGrid, setMultiFieldEditorProps);
  const rows = useTasksToEditGridRows(tasks);

  const createPayload = useCallback(
    (rows: Array<AddEditGridRow>) => {
      const payload = rows.map((row) => {
        const taskStatus = row.taskStatusId ? TaskStatusDao.id(row.taskStatusId) : undefined;
        const initialRowFieldValues = fieldValuesByItemId[row.id] ?? [];
        const workLimit = getWorkLimitPayload(row.workLimit);
        const fieldValues = createCustomFieldValuesPayload({ row, customFieldsKeyedById, initialRowFieldValues });

        return {
          id: row.id,
          name: row.name,
          taskStatus: taskStatus as TaskStatus,
          description: row.description,
          doneDate: row.doneDate,
          iterationId: row.iterationId ?? null,
          scheduleDirective: row.scheduleDirective,
          targetStart: row.targetStart,
          targetFinish: row.targetFinish,
          targetFinishType: row.targetFinishType,
          workLimit,
          workType: row.workType,
          fieldValues,
        };
      });

      return payload;
    },
    [customFieldsKeyedById, fieldValuesByItemId]
  );

  return (
    <>
      <LpEditGridModal
        columns={columns}
        createPayload={createPayload}
        fetchItems={fetchTasks}
        helpText={<strong>Bulk edit up to 50 tasks at a time</strong>}
        initialRows={rows}
        itemTypeDisplay="Tasks"
        setOpen={setOpen}
        validateRows={allRowsAreValid}
      />
      {multiFieldEditorProps && <MultiFieldValueModal {...multiFieldEditorProps} onClose={closeMultiEditModal} />}
    </>
  );
};

export default EditTaskGridModal;
