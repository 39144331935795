import { ReactNode, SyntheticEvent } from 'react';

import { arrowRightArrowLeftSolid, LpIcon, rightArrowRegular } from 'features/common/lp_icon';
import { JiraProjectModalFieldMappingPicklist } from 'features/jira_project/modal/common/jira_project_modal_field_mapping_picklist';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { useFieldMappings } from 'features/jira_project/modal/sections/field_mapping_section/use_field_mappings';
import { FieldMappingRowType, FieldMappingValue } from 'features/jira_project/modal/types';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

interface NameColumnProps {
  nameMapping: FieldMappingValue;
  icon?: ReactNode;
  picklistData?: ReadonlyArray<FieldMappingValue>;
  onChange?: (event: SyntheticEvent<HTMLElement, Event>, data: { value: string }) => void;
  rowType: FieldMappingRowType;
}

export const NameColumn = ({ nameMapping, icon, picklistData, onChange, rowType }: NameColumnProps) => {
  const { findFieldMappingItem } = useFieldMappings();
  const hasJiraCustomFieldMappingFlagEnabled = useHasFeature(FeatureFlag.jiraCustomFieldMapping);
  const fieldMappingItem = findFieldMappingItem({ rowId: nameMapping.id, rowType });
  const isTwoWaySyncEnabled = fieldMappingItem?.isPull && fieldMappingItem?.isPush;

  const content =
    hasJiraCustomFieldMappingFlagEnabled && picklistData ? (
      <JiraProjectModalFieldMappingPicklist
        picklist={picklistData}
        nameMapping={nameMapping}
        rowType={rowType}
        onChange={onChange}
        fieldChoiceId={nameMapping.id}
      />
    ) : (
      nameMapping.name
    );

  return (
    <JiraProjectModalTableNameCell
      content={content}
      leftIcon={icon}
      rightIcon={<LpIcon icon={isTwoWaySyncEnabled ? arrowRightArrowLeftSolid : rightArrowRegular} />}
      hasPicklist={!!picklistData}
    />
  );
};
