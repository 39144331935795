/* eslint-disable no-restricted-imports */
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';

import LpPopUp from '../lp_popup';

export { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
export { faSlack as slack, faJira as jira } from '@fortawesome/free-brands-svg-icons';
export {
  faAbacus as abacusLight,
  faBallPile as ballPileLight,
  faBarsStaggered as barsStaggeredLight,
  faBell as bellLight,
  faBolt as boldLight,
  faBoxAlt as boxAltLight,
  faBriefcase as briefcaseLight,
  faBullseyePointer as bullseyePointerLight,
  faBoxHeart as boxHeartLight,
  faCactus as cactusLight,
  faCameraRetro as cameraRetroLight,
  faCalendarRange as calendarRangeLight,
  faCircle as circleLight,
  faCirclePlus as circlePlusLight,
  faCircleMinus as circleMinusLight,
  faCircleXmark as circleXmarkLight,
  faClock as clockLight,
  faClockRotateLeft as clockRotateLeftLight,
  faCoffee as coffeeLight,
  faCogs as cogsLight,
  faComputerMouse as computerMouseLight,
  faCopy as copyLight,
  faDoNotEnter as doNotEnterLight,
  faEdit as editLight,
  faExclamationTriangle as exclamationTriangleLight,
  faFile as fileLight,
  faFilter as filterLight,
  faFlag as flagLight,
  faFlagCheckered as flagCheckeredLight,
  faFlask as flaskLight,
  faFolder as folderLight,
  faFolderOpen as folderOpenLight,
  faFolders as foldersLight,
  faFolderTree as folderTreeLight,
  faGift as giftLight,
  faHexagon as hexagonLight,
  faIdCard as idCardLight,
  faIdCardClip as idCardClipLight,
  faImage as imageLight,
  faImageSlash as imageSlashLight,
  faInfoCircle as infoCircleLight,
  faKey as keyLight,
  faLineColumns as lineColumnsLight,
  faLinkHorizontal as linkHorizontalLight,
  faLinkHorizontalSlash as linkHorizontalSlashLight,
  faLocationCircle as locationCircleLight,
  faMagic as magicLight,
  faMagnifyingGlassLocation as magnifyingGlassLocationLight,
  faMap as mapLight,
  faMapMarkedAlt as mapMarkedAltLight,
  faNote as noteLight,
  faPaperclip as paperclipLight,
  faPaperPlane as paperPlaneLight,
  faPencil as pencilLight,
  faPlane as planeLight,
  faPlayCircle as playCircleLight,
  faPenToSquare as penToSquareLight,
  faPoll as pollLight,
  faPresentation as presentationLight,
  faRabbitFast as rabbitFastLight,
  faRadar as radarLight,
  faRobot as robotLight,
  faScroll as scrollLight,
  faSearch as searchLight,
  faSeedling as seedlingLight,
  faSensorAlert as sensorAlertLight,
  faShieldCheck as shieldCheckLight,
  faShieldMinus as shieldMinusLight,
  faShippingFast as shippingFastLight,
  faShuttleVan as shuttleVanLight,
  faSplit as splitLight,
  faSortAmountDownAlt as sortAmountAltLight,
  faSquareKanban as squareKanbanLight,
  faSpaceShuttle as spaceShuttleLight,
  faStar as starLight,
  faStars as starsLight,
  faTable as tableLight,
  faTableCells as tableCellsLight,
  faTag as tagLight,
  faThumbtack as thumbtackLight,
  faTimesCircle as timesCircleLight,
  faTrashXmark as trashXmarkLight,
  faUserClock as userClockLight,
  faUserHeadset as userHeadsetLight,
  faUserPlus as userPlusLight,
  faUsersClass as usersClassLight,
  faWrench as wrenchLight,
} from '@fortawesome/pro-light-svg-icons';
export {
  faAngleDown as angleDownRegular,
  faAngleRight as angleRightRegular,
  faArrowRotateLeft as arrowRotateLeftRegular,
  faArrowAltRight as arrowAltRightRegular,
  faArrowRight as rightArrowRegular,
  faArrowRightToLine as arrowRightToLineRegular,
  faBan as banRegular,
  faBookOpen as bookOpenRegular,
  faCardsBlank as cardsBlankRegular,
  faCheckCircle as checkCircleRegular,
  faCircleArrowDown as circleArrowDownRegular,
  faArrowLeft as arrowLeftRegular,
  faCircleMinus as circleMinusRegular,
  faChevronDoubleLeft as chevronDoubleLeftRegular,
  faChevronDoubleRight as chevronDoubleRightRegular,
  faClockThree as clockThreeRegular,
  faCloudDownloadAlt as cloudDownloadRegular,
  faCompassDrafting as compassDraftingRegular,
  faEdit as editRegular,
  faFolder as folderRegular,
  faGlobe as globeRegular,
  faGrid2 as grid2Regular,
  faIdCard as idCardRegular,
  faKey as keyRegular,
  faLevelDownAlt as levelDownAltRegular,
  faNote as noteRegular,
  faPlusCircle as plusCircleRegular,
  faPenToSquare as penToSquareRegular,
  faRocketLaunch as rocketLaunchRegular,
  faRotate as rotateRegular,
  faShieldAlt as shieldAltRegular,
  faSquare as squareRegular,
  faStream as streamRegular,
  faTimes as timesRegular,
  faToggleOff as toggleOffRegular,
  faToggleOn as toggleOnRegular,
  faUsers as usersRegular,
} from '@fortawesome/pro-regular-svg-icons';

export {
  faAngleLeft as angleLeftSolid,
  faAngleRight as angleRightSolid,
  faAnglesRight as anglesRightSolid,
  faAperture as apertureSolid,
  faArchive as archiveSolid,
  faArrowAltDown as arrowAltDownSolid,
  faArrowAltRight as arrowAltRightSolid,
  faArrowAltSquareDown as arrowAltSquareDownSolid,
  faArrowAltSquareLeft as arrowAltSquareLeftSolid,
  faArrowAltSquareRight as arrowAltSquareRightSolid,
  faArrowAltSquareUp as arrowAltSquareUpSolid,
  faArrowAltUp as arrowAltUpSolid,
  faArrowDownWideShort as arrowDownWideShortSolid,
  faArrowTurnDown as arrowTurnDownSolid,
  faArrowTurnUp as arrowTurnUpSolid,
  faArrowUpRightAndArrowDownLeftFromCenter as arrowUpRightAndArrowDownLeftFromCenterSolid,
  faArrowUpRightFromSquare as arrowUpRightFromSquareSolid,
  faArrowUpShortWide as arrowUpShortWideSolid,
  faArrowRightArrowLeft as arrowRightArrowLeftSolid,
  faArrowRotateRight as arrowRotateRightSolid,
  faArrowsV as arrowsVSolid,
  faAt as atSolid,
  faBackspace as backspaceSolid,
  faBallPile as ballPileSolid,
  faBars as barsSolid,
  faBarsStaggered as barsStaggeredSolid,
  faBusinessTime as businessTimeSolid,
  faBookmark as bookmarkSolid,
  faBox as boxSolid,
  faBoxAlt as boxAltSolid,
  faBoxHeart as boxHeartSolid,
  faBoxOpen as boxOpenSolid,
  faBoxTaped as boxTapedSolid,
  faBullseye as bullseyeSolid,
  faBullseyePointer as bullseyePointerSolid,
  faCactus as cactusSolid,
  faCalculator as calculatorSolid,
  faCalendarDay as calendarDaySolid,
  faCalendarExclamation as calendarExclamationSolid,
  faCalendarArrowUp as calendarArrowUpSolid,
  faCaretDown as caretDownSolid,
  faCaretLeft as caretLeftSolid,
  faCaretRight as caretRightSolid,
  faChalkboard as chalkboardSolid,
  faCheck as checkSolid,
  faCheckCircle as checkCircleSolid,
  faCheckSquare as checkSquareSolid,
  faChevronCircleLeft as chevronCircleLeftSolid,
  faChevronDown as chevronDownSolid,
  faChevronRight as chevronRightSolid,
  faChevronLeft as chevronLeftSolid,
  faCircle as circleSolid,
  faCircleCheck as circleCheckSolid,
  faCircleDot as circleDotSolid,
  faCircleExclamation as circleExclamationSolid,
  faCirclePause as circlePauseSolid,
  faCirclePlus as circlePlusSolid,
  faCircleMinus as circleMinusSolid,
  faCircleSmall as circleSmallSolid,
  faCircleQuestion as circleQuestionSolid,
  faClock as clockSolid,
  faCloudCheck as cloudCheckSolid,
  faCloudExclamation as cloudExclamationSolid,
  faCloudDownload as cloudDownloadSolid,
  faCloudDownloadAlt as cloudDownloadAltSolid,
  faCloudUploadAlt as cloudUploadAltSolid,
  faCode as codeSolid,
  faCoffee as coffeeSolid,
  faCog as cogSolid,
  faCogs as cogsSolid,
  faCoin as coinSolid,
  faColumns as columnsSolid,
  faCompress as compressSolid,
  faConveyorBeltAlt as conveyorBeltAltSolid,
  faCreditCard as creditCardSolid,
  faCube as cubeSolid,
  faDotCircle as dotCircleSolid,
  faDownRight as downRightSolid,
  faEllipsisH as ellipsisHSolid,
  faEllipsisV as ellipsisVSolid,
  faEnvelopeOpenText as envelopeOpenTextSolid,
  faExclamation as exclamationSolid,
  faExclamationTriangle as exclamationTriangleSolid,
  faExpand as expandSolid,
  faEye as eyeSolid,
  faFile as fileSolid,
  faFileExcel as fileExcelSolid,
  faFileWord as fileWordSolid,
  faFilePdf as filePdfSolid,
  faFileZipper as fileZipperSolid,
  faFileVideo as fileVideoSolid,
  faFilePowerpoint as filePowerpointSolid,
  faFileLines as fileLinesSolid,
  faFileImage as fileImageSolid,
  faFileCsv as fileCsvSolid,
  faFileCode as fileCodeSolid,
  faFileAudio as fileAudioSolid,
  faFileSpreadsheet as fileSpreadSheet,
  faFilter as filterSolid,
  faFlagCheckered as flagCheckeredSolid,
  faFlagSwallowtail as flagShallowtailSolid,
  faFolderClosed as folderClosedSolid,
  faFolderOpen as folderOpenSolid,
  faFolderUser as folderUserSolid,
  faGear as gearSolid,
  faGift as giftSolid,
  faGlobe as globeSolid,
  faGraduationCap as graduationCapSolid,
  faGrid as gridSolid,
  faGripDotsVertical as gripDotsVerticalSolid,
  faHistory as historySolid,
  faHome as homeSolid,
  faIdCard as idCardSolid,
  faImage as imageSolid,
  faInfoCircle as infoCircleSolid,
  faInputNumeric as inputNumericSolid,
  faInputText as inputTextSolid,
  faIslandTropical as islandTropicalSolid,
  faKey as keySolid,
  faLampDesk as lampDeskSolid,
  faLayerGroup as layerGroupSolid,
  faLeft as leftArrowSolid,
  faLinkHorizontal as linkHorizontalSolid,
  faList as listSolid,
  faLocationArrow as locationArrowSolid,
  faLockAlt as lockAltSolid,
  faMedkit as medkitSolid,
  faMessages as messagesSolid,
  faMessageQuote as messagesQuoteSolid,
  faMapLocationDot as mapLocationDotSolid,
  faMemoCircleInfo as memoCircleInfoSolid,
  faMinusCircle as minusCircleSolid,
  faNote as noteSolid,
  faPause as pauseSolid,
  faPassport as passportSolid,
  faPaperclip as paperclipSolid,
  faPauseCircle as pauseCircleSolid,
  faPeopleGroup as peopleGroupSolid,
  faPeopleCarry as peopleCarrySolid,
  faPencil as pencilSolid,
  faPlane as planeSolid,
  faPlay as playSolid,
  faPlus as plusSolid,
  faPlusCircle as plusCircleSolid,
  faPlusSquare as plusSquareSolid,
  faPoll as pollSolid,
  faQuestionCircle as questionCircleSolid,
  faRectangleLandscape as rectangleLandscapeSolid,
  faRectangleWide as rectangleWideSolid,
  faRight as rightArrowSolid,
  faRobot as robotSolid,
  faScissors as scissorsSolid,
  faSearch as searchSolid,
  faShieldCheck as shieldCheckSolid,
  faShuffle as shuffleSolid,
  faSignOut as signOutSolid,
  faSpinner as spinnerSolid,
  faSquare as squareSolid,
  faSquare1 as squareOneSolid,
  faSquareCheck as squareCheckSolid,
  faSquareKanban as squareKanbanSolid,
  faSquareQuestion as squareQuestionSolid,
  faSquareUpRight as squareUpRightSolid,
  faShieldHalved as shieldHalvedSolid,
  faSensorCloud as sensorCloudSolid,
  faSensorFire as sensorFireSolid,
  faSquareUser as squareUserSolid,
  faSparkles as sparklesSolid,
  faStar as starSolid,
  faStarExclamation as starExclamationSolid,
  faStars as starsSolid,
  faStream as streamSolid,
  faTag as tagSolid,
  faTags as tagsSolid,
  faText as textSolid,
  faThumbtack as thumbtackSolid,
  faTimeline as timelineSolid,
  faTimesCircle as timesCircleSolid,
  faTrashXmark as trashXmarkSolid,
  faTrash as trashSolid,
  faTriangle as triangleSolid,
  faTableList as tableListSolid,
  faUser as userSolid,
  faUserAlt as userAltSolid,
  faUserCircle as userCircleSolid,
  faUserClock as userClockSolid,
  faUserCog as userCogSolid,
  faUserFriends as userFriendSolid,
  faUserGroup as userGroupSolid,
  faUserHeadset as userHeadsetSolid,
  faUserPen as userPenSolid,
  faUserPlus as userPlusSolid,
  faUserShield as userShieldSolid,
  faUserTimes as userTimesSolid,
  faUsers as usersSolid,
  faWindowClose as windowCloseSolid,
  faWindowMaximize as windowMaximizeSolid,
} from '@fortawesome/pro-solid-svg-icons';

export interface LpIconProps extends FontAwesomeIconProps {
  hoverText?: string;
}

export const LpIcon = forwardRef<SVGSVGElement, LpIconProps>(({ hoverText, ...faProps }, ref) =>
  hoverText ? (
    <LpPopUp
      positionStrategy="fixed"
      placement="top"
      trigger={<FontAwesomeIcon {...faProps} ref={ref} />}
      content={<div>{hoverText}</div>}
    />
  ) : (
    <FontAwesomeIcon {...faProps} ref={ref} />
  )
);
