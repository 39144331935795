import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router';

import { OrganizationDao } from 'daos/organization';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import PageNotFound from 'features/common/errors/page_not_found';
import {
  setOrganizationHubHasError,
  setOrganizationHubUsersFetchComplete,
} from 'features/organization_directory/slice';
import { PendingInvites } from 'features/organization_directory/users/invite/pending';
import { OrgDirUsersTopNav } from 'features/organization_directory/users/top_nav';
import useOrganizationFetchOnOrganizationModified from 'hooks/use_organization_fetch_on_organization_modified';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';

import OrgDirUsersActive from './active';
import OrgDirAdmins from './admins';
import { OrgDirUsersDashboardGuests } from './dashboard_guests';
import OrgDirUsersDisconnected from './disconnected';
import UsersInvite from './invite';
import { OrganizationDirectoryUsersProfile } from './profile';

import './index.scss';

export const OrganizationDirectoryUsers = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const isAdmin = useSelector(getCurrentOrganizationUser)?.admin;

  useOrganizationFetchOnOrganizationModified();

  useEffect(() => {
    dispatch(setOrganizationHubUsersFetchComplete(false));

    const { uuid } = dispatch(OrganizationDao.fetch({ organizationId }, { include: { includeWorkspaceUsers: true } }));
    dispatch(
      awaitRequestFinish(uuid, {
        onError: () => {
          dispatch(setOrganizationHubHasError(true));
        },
        onFinish: () => {
          dispatch(setOrganizationHubUsersFetchComplete(true));
        },
      })
    );
  }, [dispatch, organizationId]);

  return (
    <>
      <OrgDirUsersTopNav />
      <div className="organization-directory__users">
        <Switch>
          <Route path={frontend.organizationHubUser.pattern} component={OrganizationDirectoryUsersProfile} />
          <Route path={frontend.organizationHubUsersAdmins.pattern} component={isAdmin ? OrgDirAdmins : PageNotFound} />
          <Route path={frontend.organizationHubUsersDashboardGuests.pattern} component={OrgDirUsersDashboardGuests} />
          <Route path={frontend.organizationHubUsersDisconnected.pattern} component={OrgDirUsersDisconnected} />
          <Route path={frontend.organizationHubUsersPendingInvites.pattern} component={PendingInvites} />
          <Route path={frontend.organizationHubUsersInvite.pattern} component={UsersInvite} />
          <Route path={frontend.organizationHubUsers.pattern} component={OrgDirUsersActive} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
    </>
  );
};
