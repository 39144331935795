import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { Switch } from 'react-router-dom';

import { InAppNotificationsDao } from 'daos/in_app_notifications';
import { ApiAccess } from 'features/api';
import { EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { AuthenticatedGuestRoutes } from 'features/authentication/authenticated/guest';
import { EventScopes, Events } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import { useTrackPageViewedOnSegment } from 'features/common/segment/use_report_page_viewed_to_segment';
import IntegrationsRoutes from 'features/integrations/routes';
import { TopNavContext } from 'features/organization/top_nav/top_nav_context';
import { OrganizationHubRoutes } from 'features/organization_directory/routes';
import { SingleSignOn } from 'features/single_sign_on';
import { Workspace } from 'features/workspace';
import { ItemFileDownloadRedirect } from 'lib/item_file_download_redirect';
import { defaultGuestLandingPage, defaultLandingPage, frontend } from 'lib/urls';
import { getIsGuestCurrentWorkspaceUser } from 'redux/entities/selectors/user';

interface ConnectedRoutesProps {
  selectedOrgId: number;
  selectedWsId: number;
  setEntityNotFound: Dispatch<SetStateAction<EntityTypeNotFound | undefined>>;
}
export const ConnectedRoutes = ({ selectedOrgId, selectedWsId, setEntityNotFound }: ConnectedRoutesProps) => {
  const isGuestWsUser = useSelector(getIsGuestCurrentWorkspaceUser);
  const [topNavRef, setTopNavRef] = useState<HTMLElement | null>(null);
  const createTopNavRef = useCallback(
    (domNode: HTMLElement | null) => {
      if (!topNavRef && domNode) {
        setTopNavRef(domNode);
      }
    },
    [topNavRef]
  );

  useTrackPageViewedOnSegment();

  const redirectUrl = isGuestWsUser
    ? defaultGuestLandingPage.url({})
    : defaultLandingPage.url({ organizationId: selectedOrgId, workspaceId: selectedWsId });

  const dispatch = useDispatch();
  const fetchInAppNotifications = useCallback(() => {
    dispatch(InAppNotificationsDao.getCurrent({ organizationId: selectedOrgId, workspaceId: selectedWsId }));
  }, [dispatch, selectedOrgId, selectedWsId]);

  useEvents({
    event: Events.NEW_DISCUSSION_POSTS,
    callback: fetchInAppNotifications,
    scope: EventScopes.None,
  });

  return (
    <TopNavContext.Provider value={{ topNavRef }}>
      <header className="lp-top-nav" ref={createTopNavRef} />
      <Switch>
        <Redirect
          from={frontend.portableManageAccount.pattern}
          to={frontend.organizationHubManageAccount.url({
            organizationId: selectedOrgId,
          })}
        />
        <Redirect
          from={frontend.portableAcademy.pattern}
          to={frontend.academy.url({
            organizationId: selectedOrgId,
            workspaceId: selectedWsId,
          })}
        />
        <Route
          path={[frontend.dashboardGuest.pattern, frontend.dashboardPassports.pattern]}
          component={AuthenticatedGuestRoutes}
        />

        <Route path={frontend.integrations.pattern} component={IntegrationsRoutes} />

        <Route
          path={frontend.organizationHub.pattern}
          render={() => <OrganizationHubRoutes setEntityNotFound={setEntityNotFound} />}
        />

        <Route path={frontend.singleSignOn.pattern} component={SingleSignOn} />

        <Route path={frontend.api.pattern} component={ApiAccess} />

        <Route path={frontend.itemFileDownload.pattern} component={ItemFileDownloadRedirect} />

        <Route path={frontend.workspace.pattern} component={Workspace} />

        <Redirect to={redirectUrl} />
      </Switch>
    </TopNavContext.Provider>
  );
};
