import { useCallback, useEffect, useRef } from 'react';
import DataGrid, { FillEvent, PasteEvent } from 'react-data-grid';
import 'react-data-grid/lib/styles.css';

import { FrontendColumns } from 'containers/shared/custom_column/enum';
import { ScrollContext } from 'containers/shared/helpers/scroll_context';
import { PackageStatus } from 'daos/enums';
import {
  getCustomFieldPasteOrFillUpdatedTargetRow,
  getIsCustomFieldColumn,
} from 'features/common/data_grid/add_edit_grid/custom_field_helpers';
import { AddEditGridColumnKey } from 'features/common/data_grid/enums';
import { AddEditGridColumns, AddEditGridRow } from 'features/common/data_grid/types';
import { TrackingBoundingBox, useTrackBoundingBox } from 'hooks/use_track_bound_box';
import { KeypressKeys } from 'lib/helpers/keypress_keys';

import './lp_add_edit_data_grid.scss';

const SCROLL_CONTEXT_CLASS = 'lp-add-edit-scroll-context';

interface LpAddEditGridProps {
  columns: AddEditGridColumns;
  rows: Array<AddEditGridRow>;
  onFill?: (props: FillEvent<AddEditGridRow>) => AddEditGridRow;
  onRowsChange: (rows: Array<AddEditGridRow>) => void;
}

export const LpAddEditGrid = ({ columns, rows, onFill, onRowsChange }: LpAddEditGridProps) => {
  const { box, ref } = useTrackBoundingBox();
  const gridElementRef = useRef<HTMLDivElement | null>(null);

  const updateGridElementScrollRef = useCallback(() => {
    const gridElement = document.querySelector(`div.${SCROLL_CONTEXT_CLASS}`) as HTMLDivElement | null;

    if (gridElement) {
      gridElementRef.current = gridElement;
    }
  }, []);

  useEffect(() => {
    updateGridElementScrollRef();
  });

  if (!box) {
    return <TrackingBoundingBox innerRef={ref} />;
  }

  const handleFill = (props: FillEvent<AddEditGridRow>) => {
    if (onFill) {
      return onFill(props);
    }
    return getFilledRow(props);
  };

  const handlePaste = (props: PasteEvent<AddEditGridRow>) => {
    return getPastedRow(props);
  };

  return (
    <TrackingBoundingBox innerRef={ref}>
      <ScrollContext.Provider value={{ scrollableElement: gridElementRef }}>
        <div
          className="lp-add-edit-data-grid"
          style={{
            height: box.height,
            width: box.width,
          }}
          onKeyDown={(e) => {
            if (e.code === KeypressKeys.Enter) {
              e.preventDefault();
            }
          }}
        >
          <DataGrid
            cellNavigationMode="CHANGE_ROW"
            className={`rdg-light fill-grid ${SCROLL_CONTEXT_CLASS}`}
            columns={columns}
            onFill={handleFill}
            onPaste={handlePaste}
            onRowsChange={onRowsChange}
            rows={rows}
          />
        </div>
      </ScrollContext.Provider>
    </TrackingBoundingBox>
  );
};

function getFilledRow(props: FillEvent<AddEditGridRow>) {
  const { columnKey, sourceRow, targetRow } = props;

  const isPackageStatusColumn = columnKey === FrontendColumns.PackageStatus;
  const isTemplateStatus =
    targetRow.packageStatus === PackageStatus.TEMPLATE || sourceRow.packageStatus === PackageStatus.TEMPLATE;

  if (isPackageStatusColumn && isTemplateStatus) {
    return targetRow;
  }

  if (columnKey === AddEditGridColumnKey.DoneDate) {
    const targetRowDoneDate = targetRow[columnKey];
    const sourceRowDoneDate = sourceRow[columnKey];

    if (!sourceRowDoneDate && targetRowDoneDate) {
      return targetRow;
    }
  }

  const isCustomFieldKey = getIsCustomFieldColumn(columnKey);

  if (isCustomFieldKey) {
    const fieldId = Number(columnKey);
    return getCustomFieldPasteOrFillUpdatedTargetRow(fieldId, sourceRow, targetRow);
  }

  return { ...targetRow, [columnKey]: sourceRow[columnKey as AddEditGridColumnKey] };
}

function getPastedRow(props: PasteEvent<AddEditGridRow>) {
  const { sourceColumnKey, sourceRow, targetColumnKey, targetRow } = props;

  const isTemplateStatus =
    targetRow.packageStatus === PackageStatus.TEMPLATE || sourceRow.packageStatus === PackageStatus.TEMPLATE;

  const isSameRow = sourceRow.id === targetRow.id;
  const isSameColumn = sourceColumnKey === targetColumnKey;

  if (isSameColumn && !isSameRow) {
    if (sourceColumnKey === FrontendColumns.PackageStatus && isTemplateStatus) {
      return targetRow;
    }

    const isCustomFieldKey = getIsCustomFieldColumn(targetColumnKey);

    if (isCustomFieldKey) {
      const fieldId = Number(targetColumnKey);
      return getCustomFieldPasteOrFillUpdatedTargetRow(fieldId, sourceRow, targetRow);
    }

    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey as AddEditGridColumnKey] };
  }
  return targetRow;
}
