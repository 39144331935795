import classNames from 'classnames';
import { ReactNode } from 'react';
import { Table } from 'semantic-ui-react';

import 'features/jira_project/modal/jira_project_modal.scss';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

export const JiraProjectModalTableNameCell = ({
  content,
  leftIcon,
  rightIcon,
  hasPicklist,
}: {
  content: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  hasPicklist?: boolean;
}) => {
  const hasJiraCustomFieldMappingFlagEnabled = useHasFeature(FeatureFlag.jiraCustomFieldMapping);
  const whiteBackground = hasPicklist && hasJiraCustomFieldMappingFlagEnabled;

  return (
    <Table.Cell
      className={classNames(
        whiteBackground && 'jira-project-modal__content-table-name-picklist',
        'field-value picklist'
      )}
    >
      <div className="custom-field__row-field">
        <div className="jira-project-modal__content-table-name-container">
          <div
            className={classNames(
              'jira-project-modal__content-table-name-content',
              whiteBackground && 'custom-field-input'
            )}
          >
            {leftIcon} {content}
          </div>
          {rightIcon}
        </div>
      </div>
    </Table.Cell>
  );
};
