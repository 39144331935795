import { Label } from 'semantic-ui-react';

import { Iteration } from 'daos/model_types';
import { calendarArrowUpSolid, LpIcon } from 'features/common/lp_icon';
import { gray700 } from 'style/variables';

import 'containers/shared/notice_icons/icons.scss';

interface IterationLabelProps {
  iteration: Iteration;
}

export const IterationLabelRenderer = ({ iteration }: IterationLabelProps) => {
  const contentIcon = <LpIcon icon={calendarArrowUpSolid} color={iteration.color ?? gray700} />;

  return <Label basic className="iteration-label" content={contentIcon} />;
};
