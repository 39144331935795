import { validEfforts, validWorkLimit } from 'features/common/data_grid/add_edit_grid/helpers/validation_helpers';
import { AddEditGridRow } from 'features/common/data_grid/types';
import { isValidDateRange } from 'lib/helpers/date_valid';

const validItemName = (itemName: string | undefined) => {
  if (!itemName) {
    return false;
  }

  const trimmedItemName = itemName.trim();

  if (!trimmedItemName.length) {
    return false;
  }

  return true;
};

export const allRowsAreValid = (rows: Array<AddEditGridRow>) =>
  rows.every(
    (row) =>
      validItemName(row.name) &&
      isValidDateRange(row.targetStart as string, row.targetFinish as string) &&
      validWorkLimit(row.workLimit ?? 0)
  );

export const allAssignmentRowsValid = (rows: Array<AddEditGridRow>) => rows.every((row) => validEfforts(row));
