import { ReactNode } from 'react';

import 'features/jira_project/modal/jira_project_modal.scss';

export const JiraProjectModalInfo = ({
  title,
  helperText,
  testId,
  button,
}: {
  title?: string;
  helperText?: string | ReactNode;
  testId?: string;
  button?: ReactNode;
}) => (
  <section className="jira-project-modal__content-info" data-testid={testId}>
    <span className="jira-project-modal__content-info-title-container">
      <div className="jira-project-modal__content-info-title">{title}</div>
    </span>
    {helperText && (
      <div className="jira-project-modal__content-info-helper-container">
        <div className="jira-project-modal__content-info-helper-text">{helperText}</div>
      </div>
    )}
    {button && <div className="jira-project-modal__content-info-helper-button-container">{button}</div>}
  </section>
);
