import { ReactNode } from 'react';

import SchedulingLabels from 'containers/shared/notice_icons/scheduling_labels';
import { SchedulingLabelsRenderer } from 'containers/shared/notice_icons/scheduling_labels/guest';
import { ScheduleDirective } from 'daos/enums';
import { ItemMetrics } from 'daos/model_types';
import { LpIcon, clockSolid } from 'features/common/lp_icon';
import { formatAsHours } from 'lib/display_helpers/format_as_hours';
import { convertSecondsToHours } from 'lib/helpers';
import { getRemainingWorkExpected } from 'lib/helpers/item_metric_calculations';

export const taskCardMetricsMessage = (itemMetrics?: ItemMetrics) => {
  const hoursLogged = itemMetrics ? convertSecondsToHours(itemMetrics.loggedWork) : 0;
  const remainingWorkExpected = convertSecondsToHours(getRemainingWorkExpected(itemMetrics));

  return formatAsHours(hoursLogged + remainingWorkExpected);
};

const BaseTaskBoardCardFooter = ({
  metricsMessage,
  taskStatusColor,
  children,
}: {
  metricsMessage: string;
  taskStatusColor: string | undefined;
  children: ReactNode;
}) => (
  <div className="lp-task-board__card-footer">
    <div className="lp-task-board__card-footer-metrics">
      <LpIcon icon={clockSolid} color={`#${taskStatusColor}`} />
      <span>{metricsMessage}&nbsp;</span>
    </div>
    <div className="lp-task-board__card-footer-labels">{children}</div>
  </div>
);

export const GuestTaskBoardCardFooter = ({
  metricsMessage,
  taskStatusColor,
  showSchedulingLimitsLabel,
  showDependencyLabel,
  showRiskLabel,
  asapStatus,
}: {
  metricsMessage: string;
  taskStatusColor: string | undefined;
  showSchedulingLimitsLabel: boolean;
  showDependencyLabel: boolean;
  showRiskLabel: boolean;
  asapStatus: ScheduleDirective;
}) => (
  <BaseTaskBoardCardFooter metricsMessage={metricsMessage} taskStatusColor={taskStatusColor}>
    <SchedulingLabelsRenderer
      showSchedulingLimitsLabel={showSchedulingLimitsLabel}
      showDependencyLabel={showDependencyLabel}
      showRiskLabel={showRiskLabel}
      asapStatus={asapStatus}
    />
  </BaseTaskBoardCardFooter>
);

export const TaskBoardCardFooter = ({
  taskId,
  taskMetrics,
  taskStatusColor,
}: {
  taskId: number;
  taskMetrics?: ItemMetrics;
  taskStatusColor: string | undefined;
}) => (
  <BaseTaskBoardCardFooter metricsMessage={taskCardMetricsMessage(taskMetrics)} taskStatusColor={taskStatusColor}>
    <SchedulingLabels itemId={taskId} />
  </BaseTaskBoardCardFooter>
);
