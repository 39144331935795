import { getMarketingTags } from 'features/common/marketing_tags';
import { request } from 'lib/api';

import { MarketingConsentStatus, OrganizationProjectTypeType, PlanFamily } from './enums';
import { OrganizationUserFormatSettings } from './model_types';
import { defineModel } from './shared';
import { backend } from './urls';

const { createBody, resource } = defineModel({ apiType: 'registrations', type: 'REGISTER' });

const { REGISTER } = resource;

interface RegisterAccount {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  readonly marketingConsent: MarketingConsentStatus;
  organizationName: string;
  organizationProjectTypes: Array<OrganizationProjectTypeType>;
  password: string;
  planFamily?: PlanFamily;
  signupKey: string;
  tosAccepted: boolean;
  readonly creator?: Partial<OrganizationUserFormatSettings>;
}

const registerAccount = (registration: RegisterAccount) => {
  const body = { ...registration, marketingTags: getMarketingTags() };
  return request(backend.register.url({}), REGISTER, createBody(body));
};

export const RegistrationDao = {
  registerAccount,
} as const;
